import React from 'react';
import SEO from "../common/SEO";
import useDocumentTitle from '../useDocumentTitle'; 
import "./Staff.css";
import SectionTitle from '../elements/sectionTitle/SectionTitle';
import ServiceOne from '../elements/service/ServiceOne';
import ScrollAnimation from "react-animate-on-scroll";
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
 
const StaffPage = () => {
    let doc = useParams();
    let name = doc.id;
    let fb = "";
    let twitter = "";    
    let insta = "";    
    let linkedin = "";    
    let whatsapp = "";
    let role = '';
    let prof = '';
    let content = '';
    const loaderContainer = document.getElementById("loader-container");
    setTimeout(() => {
        document.getElementById("loader-container").style.display = "none";
      document.getElementById("about").style.display = "block";
    }, 3000);    

    if(name === "ganeshkumar"){
        fb = "https://www.facebook.com/ganeshkumarcgk";
        twitter = "https://www.twitter.com/ganeshkumarcgk";
        insta = "https://www.instagram.com/ganeshkumarcgk";
        linkedin = "https://www.linkedin.com/in/ganesh-kumar-cgk-0b2389191/";
        whatsapp = "https://api.whatsapp.com/send?phone=+918667616275&text=Hai";
        role = "Project Manager";
        name = "Ganesh Kumar C";
        prof = 'ganesh.jpeg';
        content = 'I am Ganesh Kumar, a highly skilled and experienced project manager with a strong background in technology and a passion for delivering outstanding results. As the Project Manager at Unobi Technologies, I am responsible for leading teams of developers and engineers to deliver projects on time and within budget.        My expertise in project management methodologies, including Agile and Waterfall, allows me to tailor my approach to meet the unique needs of each project. I am an excellent communicator and am able to build strong relationships with clients and stakeholders to ensure that projects are delivered to the highest standards.';
    }   
    if(name === "vijayapandi"){
        fb = "https://www.facebook.com/vjramlove";
        twitter = "https://www.twitter.com/vijayapandig";
        insta = "https://www.instagram.com/vj_tot_crzy";
        linkedin = "https://www.linkedin.com/in/vijayapandi/";
        whatsapp = "https://api.whatsapp.com/send?phone=+918508475639&text=Hi+Let's+talk...";
        role = "Business Director";
        name = "Vijayapandi G";
        prof = 'vijayapandi.jpeg';
        content = "I am Vijayapandi, a leader with over 4 years of experience in the IT sector who is extremely motivated and results-driven. I am now the Director of Unobi Technologies Pvt Ltd. and have a solid expertise in development, project management, business growth, and innovation. My love of technology and ability to motivate people to success have been crucial in promoting development and profitability for both my clients' businesses and myself.";
    }     
    if(name === "usmankani"){
        fb = "https://www.facebook.com/usmankani16";
        twitter = "https://twitter.com/MsdUsman07";
        insta = "https://www.instagram.com/usman_kani_8653/";
        linkedin = "https://www.linkedin.com/in/usman-kani-95586019b/";
        whatsapp = "https://api.whatsapp.com/send?phone=+918825862592&text=Hi+Let's+talk...";
        role = "UI | GRAPHIC DESIGNER";
        name = "USMAN KANI M";
        prof = 'usmankani.jpeg';
        content = "I am Usman Kani, a highly skilled and experienced graphic designer with a strong passion for creative expression and innovation. As the UI | Graphic Designer at Unobi Technologies Private Limited, I lead and manage a team of talented designers to create visually stunning designs that exceed clients' expectations. With expertise in branding, print, web, and multimedia design, I am a leader in my field and bring a high level of skill and dedication to every project.";        
    }    
    if(name === "rilwan"){
        fb = "https://www.facebook.com/profile.php?id=100089891421867";
        insta = "https://instagram.com/_rilwaa?igshid=NTc4MTIwNjQ2YQ==";
        linkedin = "https://www.linkedin.com/in/mohammed-rilwan-02304b24b/";
        whatsapp = "https://api.whatsapp.com/send?phone=+918248427260&text=Hi+Let's+talk...";
        role = "Sales Manager";
        name = "Sheik Mohammed Rilwan S";
        prof = 'rilwan.jpeg';
        content = "I am Rilwan, a highly successful sales manager with a proven track record of driving revenue growth in the technology industry. As the Sales Manager at Unobi Technologies Private Limited, I am responsible for expanding the company's customer base and establishing it as a trusted provider of technology solutions. My expertise in sales includes developing strategies, managing teams, and negotiating contracts, and I am a valuable asset to the company.";        
    }    
    if(name === "shankar"){
        fb = "https://www.facebook.com/profile.php?id=100033409779398";
        twitter = "https://twitter.com/cap_jack_jones";
        insta = "https://www.instagram.com/shankar.kantharaj/";
        linkedin = "https://www.linkedin.com/in/shankar-kantharaj-61b752229/";
         whatsapp = "https://api.whatsapp.com/send?phone=+919344451201&text=Hi+Let's+talk...";
        role = "Senior developer";
        name = "SHANKAR K";
        prof = 'shankar.jpeg';
        content = 'I am Shankar, a highly skilled and experienced senior developer at Unobi Technologies. With expertise in a variety of programming languages and frameworks, I design and develop software applications that meet the needs of clients. I am constantly seeking out new technologies and methodologies to improve the development process and deliver high-quality software. My collaborative approach with other developers and stakeholders is key to my success at Unobi Technologies.';        
    }    
    if(name === "hariram"){
        fb = "https://www.facebook.com/raj.hari.56211";
        insta = "https://www.instagram.com/michael.hari/";
        linkedin = "https://www.linkedin.com/in/hari-ram-134924202/";
        whatsapp = "https://api.whatsapp.com/send?phone=+919159361519&text=Hi+Let's+talk...";
        role = "UI | GRAPHIC DESIGNER";
        name = "HARI RAM K";
        prof = 'hariram.jpeg';
        content = "I am Hari Ram, a highly skilled and experienced graphic designer with a strong passion for creative expression and innovation. As the UI | Graphic Designer at Unobi Technologies Private Limited, I lead and manage a team of talented designers to create visually stunning designs that exceed clients' expectations. With expertise in branding, print, web, and multimedia design, I am a leader in my field and bring a high level of skill and dedication to every project.";        
    }    
    useDocumentTitle(name + ' - ' + role);    
    return (
        <>
            <SEO title={name + ' - ' + role} />
            <div class="loader-container" id="loader-container" style={{ display: "block" }}>
              <div class="loader" id="loader"></div>
            </div>            
            <section id="about" className="section theme-light dark-bg" style={{"padding": "50px 0","position": "relative","display":"none"}}>
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-6 col-lg-4 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                            <div className="about-me">
                            <div className="img">
                                <div className="img-in">
                                <img src={"./images/visit/" + prof} alt={name}/>
                                </div>
                                <div className="nav social-icons justify-content-center">
                                <a href={fb} rel="noreferrer" target="_blank">
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 320 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
                                    </svg>
                                </a>
                                <a href={twitter} rel="noreferrer" target="_blank">
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
                                    </svg>
                                </a>
                                <a href={insta} rel="noreferrer" target="_blank">
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
                                    </svg>
                                </a>
                                <a href={linkedin} rel="noreferrer" target="_blank">
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path>
                                    </svg>
                                </a>
                                <a href={whatsapp} rel="noreferrer" target="_blank">
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="1em" height="1em" viewBox="0 0 12 12" enable-background="new 0 0 12 12">
                                    <g>
                                        <path d="M8.019,6.88C7.908,6.825,7.37,6.559,7.27,6.522S7.097,6.468,7.022,6.578C6.95,6.688,6.739,6.934,6.676,7.008
                                            C6.611,7.08,6.548,7.09,6.439,7.035c-0.11-0.056-0.464-0.17-0.882-0.545C5.23,6.201,5.011,5.841,4.947,5.732
                                            c-0.064-0.11-0.008-0.169,0.047-0.225c0.049-0.049,0.109-0.128,0.165-0.191c0.055-0.064,0.073-0.111,0.11-0.183
                                            c0.037-0.073,0.018-0.137-0.01-0.192s-0.24-0.597-0.337-0.814C4.839,3.944,4.753,3.941,4.675,3.937
                                            c-0.064-0.002-0.138-0.002-0.21-0.002S4.273,3.962,4.172,4.072c-0.1,0.109-0.383,0.375-0.383,0.914c0,0.54,0.393,1.062,0.448,1.134
                                            c0.055,0.072,0.758,1.215,1.872,1.655c0.926,0.365,1.116,0.292,1.316,0.273c0.202-0.017,0.649-0.265,0.741-0.52
                                            c0.091-0.257,0.091-0.477,0.063-0.521C8.202,6.961,8.128,6.934,8.019,6.88z"/>
                                        <path d="M9.122,2.86L9.122,2.86c-0.828-0.83-1.93-1.286-3.104-1.287c-2.419,0-4.388,1.969-4.388,4.388
                                            C1.629,6.734,1.832,7.489,2.215,8.154l-0.622,2.272l2.326-0.609c0.64,0.349,1.363,0.532,2.097,0.534h0.002
                                            c2.418,0,4.387-1.969,4.388-4.387C10.406,4.792,9.95,3.69,9.122,2.86z M6.019,9.61L6.019,9.61C5.362,9.609,4.721,9.435,4.16,9.102
                                            L4.027,9.023l-1.38,0.362l0.368-1.347L2.929,7.901c-0.365-0.58-0.558-1.251-0.558-1.94c0-2.011,1.638-3.646,3.649-3.646
                                            c0.974,0,1.89,0.379,2.578,1.069c0.69,0.689,1.068,1.605,1.068,2.58C9.665,7.974,8.029,9.61,6.019,9.61z"/>
                                    </g>
                                </svg>
                                </a>
                                </div>
                            </div>
                            <div className="info">
                                <p>{role}</p>
                                <h3>{name}</h3>
                            </div>
                            </div>
                        </div>
                        <div className="col-lg-7 ml-auto aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                            <div className="about-info">
                            <div className="title">
                                <h3>Hi,</h3>
                            </div>
                            <div className="about-text">
                                <p>{content}</p>
                                <div className="card-button">
                                    <a href='https://unobitech.com/' target="_blank" className="btn btn-default">Our Website</a>
                                    <a href='https://unobitech.com/portfolio' target="_blank" className="btn btn-default">Our Portfolio</a>
                                    <a href='https://www.google.com/maps/place/Unobi+Technologies+Pvt+Ltd,+Railway+Station,+416%2F13+-+S.J.+Complex,Thendral+Nagar,+Opps,+Sivakasi,+Tamil+Nadu+626123/@9.462051,77.788986,14z/data=!4m6!3m5!1s0x3b06cfc473656525:0x7bcec887c3c0a108!8m2!3d9.4620506!4d77.7889855!16s%2Fg%2F11shlmy023?hl=en&gl=IN' target="_blank" className="btn btn-default">Our Location</a>
                                    <a href='https://unobitech.com/contact' target="_blank" className="btn btn-default">Contact Us</a>                                                                        
                                    <a href='tel:63696 06204' className="btn btn-default">Call Now</a>                                    
                                    <a href="https://api.whatsapp.com/send?phone=+9163696 06204&text=Hi+Let's+talk..." target="_blank" className="btn btn-default">WhatsApp</a>                                    
                                </div>                                                         
                            </div>
                            </div>
                        </div>
                    </div>
                    
                {/* Start Service Area  */}
                <div className="rn-service-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "What we can do for you"
                                    title = "Our Services"
                                    description = "We pride ourselves on our ability to deliver high-quality work on time and within budget. Our team of experienced professionals has a wealth of knowledge and expertise in the technology field and we are committed to providing our clients with the best possible solutions."
                                    />
                            </div>
                        </div>
                        <ServiceOne 
                            serviceStyle = "service__style--1 bg-color-blackest radius mt--25"
                            textAlign = "text-center"
                            />
                             
                    </div>
                </div>
                </div>
            </section>
        </>
    )
}

export default StaffPage
