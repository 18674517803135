import React from 'react';
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin, FiChevronRight, FiMapPin, FiPhone } from "react-icons/fi";
import { Link } from 'react-router-dom'

const HeaderTopBar = () => {
    return (
        <div className="header-top-bar">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4 col-md-12 col-12">
                        <div className="header-left">
                            {/* <p><a href="#link">Get the most advanced template <FiChevronRight /></a></p> */}
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-12 col-12">
                        <div className="header-right">
                            <div className="address-content">
                                <p><FiMapPin /><span>India & USA</span></p>
                                <p><FiPhone /><span><a href="tel:+91 63696 06204">+91 63696 06204</a></span></p>
                            </div>
                            <div className="social-icon-wrapper">
                                <ul className="social-icon social-default icon-naked">
                                    <li><a href="https://www.facebook.com/unobitech" target={"_blank"}><FiFacebook /></a></li>
                                    <li><a href="https://twitter.com/unobi_tech" target={"_blank"}><FiTwitter /></a></li>
                                    <li><a href="https://www.instagram.com/unobitech/" target={"_blank"}><FiInstagram /></a></li>
                                    <li><a href="https://www.linkedin.com/company/unobi-technologies-pvt-ltd/" target={"_blank"}><FiLinkedin /></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderTopBar;
