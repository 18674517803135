import React from 'react';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import Copyright from '../common/footer/Copyright';
import { FiArrowRight,FiCheck } from "react-icons/fi";
import Separator from "../elements/separator/Separator"; 
import AboutTwo from "../elements/about/AboutTwo";
import AboutTwoInverted from "../elements/about/AboutTwoInverted";
import CalltoActionSix from "../elements/calltoaction/CalltoActionSix"; 
import TimelineOne from "../elements/timeline/TimelineOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import FooterOne from '../common/footer/FooterOne';
import HeaderTopBar from '../common/header/HeaderTopBar';
import useDocumentTitle from '../useDocumentTitle'; 
import ScrollAnimation from "react-animate-on-scroll";
import {Link} from "react-router-dom";
 
const TimelineData = [
    {
        id: "1",
        date: "Step-1",
        title: "Requirements gathering",
        description: "We work with you to understand your business requirements and goals, and develop a detailed project plan.        ",
        image: "timeline-01",
        workingStep: [
             
        ]
    },
    {
        id: "2",
        date: "Step-2",
        title: "Conceptualization and Design",
        description: "Our design team creates wireframes, prototypes, and visual designs for your app, ensuring that it meets your brand standards and provides a great user experience.",
        image: "timeline-02",
        workingStep: [
            // {
            //     stepTitle: "Follow this process",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
            // {
            //     stepTitle: "Working experience",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
        ]
    },
    {
        id: "3",
        date: "Step-3",
        title: "Development:",
        description: "Our development team starts building the app using the latest technologies and best practices to ensure that it is robust, scalable, and secure.",
        image: "timeline-02",
        workingStep: [
            // {
            //     stepTitle: "Follow this process",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
            // {
            //     stepTitle: "Working experience",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
        ]
    },
   
    {
        id: "4",
        date: "Step-4",
        title: "Testing:",
        description: "We perform thorough testing to ensure that the app is functioning as intended and is free of bugs and other technical issues.",
        image: "timeline-02",
        workingStep: [
            // {
            //     stepTitle: "Follow this process",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
            // {
            //     stepTitle: "Working experience",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
        ]
    },
    {
        id: "5",
        date: "Step-5",
        title: "Deployment:",
        description: "Once the app has been thoroughly tested and approved, we deploy it to the app store of your choice, making it available to users worldwide.",
        image: "timeline-02",
        workingStep: [
            // {
            //     stepTitle: "Follow this process",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
            // {
            //     stepTitle: "Working experience",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
        ]
    },
    {
        id: "6",
        date: "Step-6",
        title: "Maintenance and Support:",
        description: "We provide ongoing maintenance and support to ensure that the app is running smoothly and that any issues are quickly addressed.",
        image: "timeline-02",
        workingStep: [
            // {
            //     stepTitle: "Follow this process",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
            // {
            //     stepTitle: "Working experience",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
        ]
    },
]
const VideoEditing = () => {
    useDocumentTitle('Expert Video Editing Services | Unobi Tech');    
    return (
        <>
            <SEO title="Expert Video Editing Services | Unobi Tech" />
            <main className="page-wrapper">
                <HeaderTopBar />
                <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-6 shape-right height-750">
                    <div className="container">
                        <div className="row row--30 align-items-center">
                            <div className="order-2 order-lg-1 col-lg-6 mt_md--50 mt_sm--50 mt_lg--30">
                                <div className="inner text-start">
                                    <h1 className="title color-white">Video<br /> Editing </h1>
                                    <p className="description color-white">Transform your footage into a masterpiece with our video editing services</p>
                                    <div className="button-group mt--30 mt_sm--20">
                                        <a className="btn-default btn-medium btn-icon btn-border" target="_blank" href="/contact">Enquire Now <i className="icon"><FiArrowRight /></i></a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 order-1 order-lg-2">
                                <div className="thumbnail">
                                    <img src="./images/banner/videoediting_page_banner.webp" alt="Unobi technologies" />
                                </div>
                            </div>
                            <div className="shape-image">
                                <img src="./images/banner/white-shape.svg" alt="Unobi technologies" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />
                
                
                <div className="rwt-about-area about-style-2 rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/unobi_tech_video_editing_about.webp" alt="Unobi technologies" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h4 className="subtitle"><span className="theme-gradient">About Our video editing service.</span></h4>
                                    <h2 className="title mt--10"> Introduction to Unobi Tech's video editing</h2>
                                </ScrollAnimation>

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <p>Unobi Tech's video editing services offer businesses professional and high-quality video editing services that can help you showcase your brand, products, and services in the best light. Our team of experienced video editors uses cutting-edge technology and creative techniques to craft videos that are visually appealing, engaging, and effective. Whether you're looking to create promotional content, product demos, or social media videos, Unobi Tech's Video Editing Services can help you achieve your goals. With our focus on high-quality results, efficient workflows, and exceptional customer service, Unobi Tech is the ideal partner for businesses looking to take their video content to the next level.</p>

                                    {/* <ul className="list-icon">
                                        <li><span className="icon"><FiCheck /></span> Track your teams progress with mobile app.</li>
                                        <li><span className="icon"><FiCheck /></span> Lorem ipsum dolor sit amet consectetur adipisicing.</li>
                                        <li><span className="icon"><FiCheck /></span> Ipsum dolor sit amet consectetur adipisicing.</li>
                                        <li><span className="icon"><FiCheck /></span> Your teams progress with mobile app.</li>
                                    </ul> */}
                                </ScrollAnimation>
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <div className="read-more-btn mt--40">
                                        <Link className="btn-default btn-icon" to="contact">Get More Info<i className="icon"><FiArrowRight /></i></Link>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
                </div>
                <Separator />
                
                <div className="rwt-about-area about-style-2 rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                  
                <div className="col-lg-7 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h4 className="subtitle"><span className="theme-gradient">More about....</span></h4>
                                    <h2 className="title mt--10">How we do?</h2>
                                </ScrollAnimation>

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <p>Unobi Tech's video editing process is a collaborative effort between our clients and our experienced video editors. We start with an initial consultation to understand your vision and goals for the project. Then, we work to gather all the necessary footage and materials. Our editors then use state-of-the-art editing software and techniques to bring your vision to life. Throughout the process, we work closely with you to make sure that the final product meets your standards and exceeds your expectations. Whether you're looking to create a corporate video, a marketing video, or an event recap, Unobi Tech's video editing team has the expertise to help you tell your story in a visually compelling way.</p>

                                    <ul className="list-icon">
                                        <li><span className="icon"><FiCheck /></span> Transform raw footage into compelling stories.</li>
                                        <li><span className="icon"><FiCheck /></span> Expert use of editing software for seamless cuts.</li>
                                        <li><span className="icon"><FiCheck /></span> Enhance visual & audio to captivate audience.</li>
                                        <li><span className="icon"><FiCheck /></span> Create high-quality, professional videos.</li>
                                    </ul>
                                </ScrollAnimation>
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <div className="read-more-btn mt--40">
                                    <a className="btn-default btn-icon" href="tel:+91 63696 06204">Call Now <i className="icon"><FiArrowRight /></i></a>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                  
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/unobi_tech_videoediting_page_about.webp" alt="Unobi technologies" />
                        </div>
                    </div>

                </div>
            </div>   
        </div>
                <Separator />
                  {/* Start Timeline Area  */}
                  {/* <div className="rwt-timeline-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            subtitle = "App Development"
                                            title = "Our development process."
                                        />
                                </div>
                            </div>

                            
                            <div className="row">
                                <div className="col-lg-12">
                                <div className="rn-timeline-wrapper timeline-style-one position-relative">
                                    <div className="timeline-line"></div>

                                    {TimelineData.map((data, index) =>(
                                        <div className="single-timeline mt--50" key={index}>
                                            <div className="timeline-dot">
                                                <div className="time-line-circle"></div>
                                            </div>
                                            <div className="single-content">
                                                <div className="inner">
                                                    <div className="row row--30 align-items-center">
                                                        <div className="order-2 order-lg-1 col-lg-6 mt_md--40 mt_sm--40">
                                                            <div className="content">
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                    <span className="date-of-timeline">{data.date}</span>
                                                                </ScrollAnimation>

                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                    <h2 className="title">{data.title}</h2>
                                                                </ScrollAnimation>

                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                    <p className="description">{data.description}</p>
                                                                </ScrollAnimation>

                                                                <div className="row row--30">
                                                                    {data.workingStep.map((data, index)=>(
                                                                        <div className="col-lg-6 col-md-6 col-12" key={index}>
                                                                            <div className="working-list">
                                                                                <ScrollAnimation 
                                                                                animateIn="fadeInUp"
                                                                                animateOut="fadeInOut"
                                                                                animateOnce={true}>
                                                                                    <h5 className="working-title">{data.stepTitle}</h5>
                                                                                </ScrollAnimation>

                                                                                <ScrollAnimation 
                                                                                animateIn="fadeInUp"
                                                                                animateOut="fadeInOut"
                                                                                animateOnce={true}>
                                                                                    <p>{data.stepDescription}</p>
                                                                                </ScrollAnimation>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="order-1 order-lg-2 col-lg-6">
                                                            <div className="thumbnail">
                                                                <img className="w-100" src={`./images/timeline/${data.image}.webp`} alt="Unobi technologies Private Limited" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                
                                </div>
                                </div>
                            </div>

                        </div>
                    </div> */}
                    {/* End Timeline Area  */}

                <Separator />
                <CalltoActionSix />
  
                <FooterOne />
                <Copyright />
            </main>
        </>
    )
}

export default VideoEditing
