import React from 'react';
import ContactForm from "./ContactForm";
import GoogleMapStyle from "./GoogleMapStyle";
import { FiHeadphones , FiMail , FiMapPin, FiPhoneCall } from "react-icons/fi";

const ContactOne = () => {
    return (
        <>
            <div className="row row--15">
                <div className="col-lg-12">
                    <div className="rn-contact-address mt_dec--30">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiPhoneCall />
                                    </div>
                                    <div className="inner">
                                        {/* <h4 className="title">Contact phone number</h4> */}
                                        <p><a href="tel:+91 63696 06204">+91 63696 06204</a></p>
                                        <p><a href="tel:+91 85084 75639">+91 85084 75639</a></p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        {/* <h4 className="title">Our email address</h4> */}
                                        <p><a href="mailto:info@unobitech.com">info@unobitech.com</a></p>
                                        <p><a href="mailto:support@unobitech.com">support@unobitech.com</a></p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        {/* <h4 className="title">Our location</h4> */}
                                        <p>416/13 - S.J. Complex, <br></br> Thendral Nagar, Opposite Railway Station, Sivakasi,<br></br> Tamil Nadu 626123</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt--40 row--15">
                <div className="col-lg-7">
                    <ContactForm formStyle="contact-form-1" />
                </div>
                <div className="col-lg-5 mt_md--30 mt_sm--30">
                    <GoogleMapStyle />
                </div>
            </div>
        </>
    )
}
export default ContactOne;