import React from 'react';
import SEO from "../../common/SEO";
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import PortfolioOne from "./PortfolioOne";
import HeaderTopBar from '../../common/header/HeaderTopBar';
import HeaderOne from '../../common/header/HeaderOne';
import CalltoActionFive from '../calltoaction/CalltoActionFive';
import FooterOne from '../../common/footer/FooterOne';
import Copyright from '../../common/footer/Copyright';
import BrandTwo from '../brand/BrandTwo';
import useDocumentTitle from '../../useDocumentTitle';

const PortfolioThreeColumn = () => {
    useDocumentTitle('Professional Portfolio | Best Software Development Company');
    return (
        <>
            <SEO title="Professional Portfolio | Best Software Development Company" />
                <HeaderTopBar />
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />
                {/* Start Slider area  */}
                <div className="slider-area slider-style-2 variation-3 height-850 bg_image bg_image_fixed theme-shape" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/banner/portfolio_banner.webp)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="inner text-start">
                                    <h1 className="title">Hi there, <br />We are <br></br><span className="theme-gradient">Unobi Tech!</span></h1>
                                    <p className="description">A full-service software development company delivering innovative solutions to businesses. Our expert team specializes in web, app, desktop, IoT, graphics design, UI/UX, video editing, and more. Partner with us for the best results!</p>
                                    <div className="button-group mt--40 mt_sm--20">
                                        <Link className="btn-default btn-icon" to="/about-us">About Us <i className="icon"><FiArrowRight /></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider area  */}
                <div className="main-content">
                    <div className="rwt-portfolio-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            subtitle = "Portfolio"
                                            title = "Check our amazing works"
                                            description = ""
                                        />
                                </div>
                            </div>
                            <PortfolioOne Column="col-lg-4 col-md-6 mt--30 portfolio"  />
                        </div>
                    </div>
                </div>
                <div className="rwt-brand-area rn-section-gapBottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Our expertise in the following"
                                    title = "TECHNOLOGIES"
                                    description = ""
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mt--10">
                                <BrandTwo brandStyle="brand-style-2" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rwt-callto-action-area rn-section-gapBottom">
                    <div className="wrapper">
                        <CalltoActionFive />
                    </div>
                </div>                
                <FooterOne />
                <Copyright/>

        </>
    )
}
export default PortfolioThreeColumn;
