import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap';

const CareersCollapse = ({customStyle}) => {
    return (
        <Accordion className={`rn-accordion-style ${customStyle}`} defaultActiveKey="0">
            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    Python Internship Opportunity: Join Our Team Today!
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>Are you a Python enthusiast looking for an opportunity to kick-start your career? We are seeking a talented individual to join our team as a Python Intern and help us build innovative software solutions for our clients.
                        As a Python Intern, you will work alongside experienced developers and gain hands-on experience in developing software applications using Python. You will have the opportunity to learn new skills, work on challenging projects, and receive mentorship from our team of experts.
                        <br></br><br></br>
                        Key Responsibilities:
                        <br></br><br></br>
                        Assist in designing and developing software applications using Python.
                        Collaborate with cross-functional teams to identify and implement software solutions.
                        Write clean, scalable, and testable code.
                        Troubleshoot and debug software issues.
                        Stay up-to-date with emerging trends and technologies in software development.
                        Requirements:

                        Currently pursuing a degree in Computer Science, Engineering or related field.
                        Strong knowledge of Python programming language.
                        Familiarity with web development frameworks such as Django or Flask.
                        Basic understanding of databases and SQL.
                        Excellent problem-solving skills.
                        Strong communication and interpersonal skills.
                        If you are passionate about Python programming and are looking for an opportunity to gain real-world experience, we want to hear from you! Please submit your resume and cover letter for consideration. This is a paid internship with the potential for a full-time position upon completion.
                    </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    Automation RPA Internship Opportunity: Join Our Team Today!
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>Are you interested in the exciting world of Robotic Process Automation (RPA)? We are seeking a talented individual to join our team as an Automation RPA Intern and help us build innovative software solutions for our clients.

                        As an Automation RPA Intern, you will work alongside experienced developers and gain hands-on experience in implementing RPA solutions using leading RPA platforms. You will have the opportunity to learn new skills, work on challenging projects, and receive mentorship from our team of experts.
                        <br></br><br></br>
                        Key Responsibilities:
                        <br></br><br></br>
                        Assist in designing and implementing RPA solutions using leading RPA platforms.
                        Collaborate with cross-functional teams to identify and implement software solutions.
                        Develop automation workflows using programming languages such as Python or Java.
                        Test and debug automation workflows to ensure they meet quality standards.
                        Stay up-to-date with emerging trends and technologies in RPA.
                        Requirements:

                        Currently pursuing a degree in Computer Science, Engineering or related field.
                        Basic understanding of programming concepts and software development methodologies.
                        Strong analytical and problem-solving skills.
                        Excellent communication and interpersonal skills.
                        Passion for automation and eagerness to learn new technologies.
                        If you are interested in the exciting field of RPA and are looking for an opportunity to gain real-world experience, we want to hear from you! Please submit your resume and cover letter for consideration.
                    </Card.Body>
                </Accordion.Collapse>
            </Card>

            {/* <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                    How can I get the customer support?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                    <Card.Body>After purchasing the product need you any support you can be share with us with sending mail to rainbowit10@gmail.com.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    Can I get update regularly and For how long do I get updates?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Card.Body>Yes, We will get update the Trydo. And you can get it any time. Next time we will comes with more feature. You can be get update for unlimited times. Our dedicated team works for update.</Card.Body>
                </Accordion.Collapse>
            </Card> */}

        </Accordion>
    )
}
export default CareersCollapse;

