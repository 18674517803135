import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;
class GoogleMapStyle extends Component {
  static defaultProps = {
    center: {
      lat: 9.463514410302711,
      lng: 77.78891924857209
    },
    zoom: 11
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div className="google-map-style-1">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15742.294613874543!2d77.78690222728827!3d9.4587309415754!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b06cfc473656525%3A0x7bcec887c3c0a108!2sUnobi%20Technologies%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1675754783830!5m2!1sen!2sin" width="600" height="450" style={{"border":"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    );
  }
}

export default GoogleMapStyle;