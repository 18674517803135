import React from 'react';
import SEO from '../common/SEO';
import HeaderTopBar from '../common/header/HeaderTopBar';
import HeaderOne from '../common/header/HeaderOne';
import BreadcrumbOne from '../elements/breadcrumb/BreadcrumbOne';
import SectionTitle from '../elements/sectionTitle/SectionTitle';
import CareersCollapse from '../elements/careers/CareersCollapse';
import CareersForm from '../elements/careers/CareersForm';
import Separator from '../elements/separator/Separator';
import FooterOne from '../common/footer/FooterOne';
import Copyright from '../common/footer/Copyright';
import useDocumentTitle from '../useDocumentTitle';

const Careers = () => {
    useDocumentTitle('Careers | Join Our Team | Unobi Tech');                
    return (
        <>
            <SEO title="Careers | Join Our Team | Unobi Tech" />
            <HeaderTopBar />
                <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-transparent" />
                <BreadcrumbOne 
                    title="Join Our Team. Exciting Career Opportunities Await You!"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Careers"
                />
                <div className="main-content">


                    {/* Start Accordion Area  */}
                    <div className="rn-accordion-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-10 offset-lg-1">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Unobi Tech - Careers"
                                        title = "Build your career today!"
                                        description = ""
                                    />
                                </div>
                            </div>
                            <div className="row mt--35 row--20">
                                <div className="col-lg-6 col-sm-12">
                                    <CareersCollapse customStyle="" />
                                </div>
                                <div className="col-lg-6 col-sm-12">
                                    <CareersForm />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Accordion Area  */}
                    <Separator />

                </div>
                <FooterOne />
                <Copyright />
        </>
    )
}
export default Careers;