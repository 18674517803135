import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";

const TimelineData = [
    {
        id: "1",
        title: "Ideation & Planning",
        description: "During this stage, we gather requirements and brainstorm ideas to define project scope, timeline, and budget.",
    },
    {
        id: "2",
        title: "MVP,Design & Development",
        description: "Our experts design and develop an MVP aligned with your business plan, identifying core features, managing costs, and testing iteratively. Quickly validate your idea, refine your roadmap, and prepare for growth with our guidance.",
    },
    {
        id: "3",
        title: "Testing & Optimization",
        description: "Testing and optimization ensure that the MVP is functioning as intended and meets the standards of quality.",
    },
    {
        id: "4",
        title: "Launch & Maintenance",
        description: "Finally, we launch the product and provide ongoing maintenance and support to ensure its continued success.",
    },
]

const TimelineTwo = ({classVar}) => {
    return (
        <div className="timeline-style-two bg-color-blackest">
            <div className="row row--0">
                {/* Start Single Progress */}
                {TimelineData.map((data , index) =>(
                    <div className={`col-lg-3 col-md-3 rn-timeline-single ${classVar}`} key={index}>
                        <div className="rn-timeline">
                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <h6 className="title">{data.title}</h6>
                            </ScrollAnimation>
                            <div className="progress-line">
                                <div className="line-inner"></div>
                            </div>
                            <div className="progress-dot">
                                <div className="dot-level">
                                    <div className="dot-inner"></div>
                                </div>
                            </div>
                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <p className="description">{data.description}</p>
                            </ScrollAnimation>
                        </div>
                    </div>
                ))}
                {/* End Single Progress */}
            </div>
        </div>
    )
}
export default TimelineTwo;