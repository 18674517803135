import React from 'react';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import Copyright from '../common/footer/Copyright';
import { FiArrowRight,FiCheck } from "react-icons/fi";
import Separator from "../elements/separator/Separator"; 
import AboutTwo from "../elements/about/AboutTwo";
import AboutTwoInverted from "../elements/about/AboutTwoInverted";
import CalltoActionSix from "../elements/calltoaction/CalltoActionSix"; 
import TimelineOne from "../elements/timeline/TimelineOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import FooterOne from '../common/footer/FooterOne';
import HeaderTopBar from '../common/header/HeaderTopBar';
import useDocumentTitle from '../useDocumentTitle'; 
import ScrollAnimation from "react-animate-on-scroll";
import {Link} from "react-router-dom";
 
const TimelineData = [
    {
        id: "1",
        date: "Step-1",
        title: "Understanding the client's needs and goals:",
        description: "The designer will work with the client to understand their design needs and the purpose of the design project.",
        image: "unobi-tech-graphic-page-step1",
        workingStep: [
             
        ]
    },
    {
        id: "2",
        date: "Step-2",
        title: "Research and concept development: ",
        description: "The designer will conduct research to understand the target audience and industry, and then develop initial design concepts",
        image: "unobi-tech-graphic-page-step2",
        workingStep: [
            // {
            //     stepTitle: "Follow this process",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
            // {
            //     stepTitle: "Working experience",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
        ]
    },
    {
        id: "3",
        date: "Step-3",
        title: "Presentation of concepts:",
        description: "The designer will present their concepts to the client and gather feedback.",
        image: "unobi-tech-graphic-page-step3",
        workingStep: [
            // {
            //     stepTitle: "Follow this process",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
            // {
            //     stepTitle: "Working experience",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
        ]
    },
   
    {
        id: "4",
        date: "Step-4",
        title: "Refinement of design: ",
        description: "Based on feedback, the designer will refine the design until the client is satisfied.",
        image: "unobi-tech-graphic-page-step4",
        workingStep: [
            // {
            //     stepTitle: "Follow this process",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
            // {
            //     stepTitle: "Working experience",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
        ]
    },
    {
        id: "5",
        date: "Step-5",
        title: "Production:",
        description: "The final design is produced, either by hand or using digital tools, and prepared for use.",
        image: "unobi-tech-graphic-page-step5",
        workingStep: [
            // {
            //     stepTitle: "Follow this process",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
            // {
            //     stepTitle: "Working experience",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
        ]
    },
    {
        id: "6",
        date: "Step-6",
        title: "Final review and approval:",
        description: "The client reviews the final design and approves it for use.",
        image: "unobi-tech-graphic-page-step6",
        workingStep: [
            // {
            //     stepTitle: "Follow this process",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
            // {
            //     stepTitle: "Working experience",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
        ]
    },
    {
        id: "7",
        date: "Step-7",
        title: "Delivery:",
        description: "The final design files are delivered to the client in the required format(s).",
        image: "unobi-tech-graphic-page-step7",
        workingStep: [
            // {
            //     stepTitle: "Follow this process",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
            // {
            //     stepTitle: "Working experience",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
        ]
    },
]
const ServiceList = [
    {
        icon: '/images/service/graphics/logo_design.svg',
        title: 'Logo design',
        description: 'The creation of a unique, recognizable symbol that represents a company or brand.'
    },
    {
        icon:'/images/service/graphics/product_identity_design.svg',
        title: 'Brand identity design',
        description: 'The development of a consistent visual style that represents a brand across all its media.'
    },
    {
        icon: '/images/service/graphics/product_identity_design.svg',
        title: 'Print design ',
        description: 'Design of materials intended for printing, such as brochures, flyers, and posters.'
    },
    {
        icon: '/images/service/graphics/packaging_design.svg',
        title: 'Packaging design ',
        description: 'The design of product packaging, including boxes, labels, and more.'
    },
    {
        icon: '/images/service/graphics/website_design.svg',
        title: 'Website design',
        description: "The design of a company's website, including layout, color scheme, typography, and images"
    },
    {
        icon: '/images/service/graphics/advertisement_design.svg',
        title: 'Advertising design',
        description: "The design of advertisements for various media, such as print, digital, and television."
    },

    {
        icon: '/images/service/graphics/infographics_design.svg',
        title: 'Info graphic design',
        description: "The design of visual representations of information, using graphics, charts, and other visual elements."
    },
    {
        icon: '/images/service/graphics/motion_design.svg',
        title: 'Motion design',
        description: "The design of animation and moving graphics, used in advertisements, presentations, and other digital media."
    },
    {
        icon: '/images/service/graphics/illustration.svg',
        title: 'Illustration',
        description: "The creation of original artwork, including drawings, paintings, and digital illustrations, for use in various graphic design projects such as advertisements, children's books, and more."
    },
]
const GraphicsDesigning = () => {
    useDocumentTitle('Graphic Design Services | Unobi Tech');    
    return (
        <>
            <SEO title="Graphic Design Services | Unobi Tech" />
            <main className="page-wrapper">
                <HeaderTopBar />
                <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-6 shape-right height-750">
                    <div className="container">
                        <div className="row row--30 align-items-center">
                            <div className="order-2 order-lg-1 col-lg-6 mt_md--50 mt_sm--50 mt_lg--30">
                                <div className="inner text-start">
                                    <h1 className="title color-white">Graphics <br /> Designing</h1>
                                    <p className="description color-white">Bring your visions to life with Unobi Tech's graphic designing services.</p>
                                    <div className="button-group mt--30 mt_sm--20">
                                        <a className="btn-default btn-medium btn-icon btn-border" target="_blank" href="/contact">Enquire Now <i className="icon"><FiArrowRight /></i></a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 order-1 order-lg-2">
                                <div className="thumbnail">
                                    <img src="./images/banner/unobi_tech_graphicdesign_page_banner.webp" alt="Unobi technologies" />
                                </div>
                            </div>
                            <div className="shape-image">
                                <img src="./images/banner/white-shape.svg" alt="Unobi technologies" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />
                
                
                <div className="rwt-about-area about-style-2 rn-section-gap">
                    <div className="container">
                        <div className="row row--30 align-items-center">
                            <div className="col-lg-5">
                                <div className="thumbnail">
                                    <img className="w-100" src="./images/about/unobi_tech_graphicdesign_page_banner1.webp" alt="Unobi technologies" />
                                </div>
                            </div>
                            <div className="col-lg-7 mt_md--30 mt_sm--30">
                                <div className="content">
                                    <div className="section-title">

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <h4 className="subtitle"><span className="theme-gradient">About our graphic designing.</span></h4>
                                            <h2 className="title mt--10"> Graphics designing</h2>
                                        </ScrollAnimation>

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <p>Having a mobile app is important for businesses as it provides them with a platform to reach their target audience and increase brand visibility. It offers a convenient and interactive way to engage with customers, leading to increased customer loyalty and satisfaction. A well-designed app can streamline the purchasing process, making it easier for customers to make purchases, resulting in increased sales and revenue. A mobile app allows businesses to provide a personalized and seamless experience to their customers, which can lead to higher customer satisfaction and repeat business. With the widespread adoption of Smartphone and tablets, a mobile app can help businesses reach a wider audience and expand their customer base. Additionally, a mobile app provides businesses with access to real-time data and insights about their customers, allowing them to make informed decisions and improve their offerings.</p>

                                            <ul className="list-icon">
                                                <li><span className="icon"><FiCheck /></span> Creative designs for all media types.</li>
                                                <li><span className="icon"><FiCheck /></span> High-quality graphics for branding and marketing.</li>
                                                <li><span className="icon"><FiCheck /></span> Affordable pricing for all budgets.</li>
                                                <li><span className="icon"><FiCheck /></span> Experienced design team delivering stunning visuals.</li>
                                            </ul>
                                        </ScrollAnimation>
                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <div className="read-more-btn mt--40">
                                                <Link className="btn-default btn-icon" to="contact">Get More Info <i className="icon"><FiArrowRight /></i></Link>
                                            </div>
                                        </ScrollAnimation>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>   
                </div>
                <Separator />
                
                <Separator />
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "What we can do for you"
                                    title = "Services provide for you"
                                    description = ""
                                    />
                            </div>
                        </div>                
                        <div className="row row--15 service-wrapper">
                        {ServiceList.map( (val , i) => (
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <div className={`service service__style--1 bg-color-blackest radius mt--25 text-center`}>
                                        <div className="icon">
                                            <img src={`${val.icon}`} className="service-img-height" alt='Unobi technologies'/>
                                        </div>
                                        <div className="content">
                                            <h4 className="title w-600"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                            <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                                        </div>
                                    </div>
                                </ScrollAnimation>
                            </div>
                            ))}
                        </div>                
                    </div>
                </div>
                  {/* Start Timeline Area  */}
                  <div className="rwt-timeline-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            subtitle = "Graphics designing"
                                            title = "Our development process"
                                        />
                                </div>
                            </div>

                            
                            <div className="row">
                                <div className="col-lg-12">
                                <div className="rn-timeline-wrapper timeline-style-one position-relative">
                                    <div className="timeline-line"></div>
                                    {/* Start Single Timeline  */}

                                    {TimelineData.map((data, index) =>(
                                        <div className="single-timeline mt--50" key={index}>
                                            <div className="timeline-dot">
                                                <div className="time-line-circle"></div>
                                            </div>
                                            <div className="single-content">
                                                <div className="inner">
                                                    <div className="row row--30 align-items-center">
                                                        <div className="order-2 order-lg-1 col-lg-6 mt_md--40 mt_sm--40">
                                                            <div className="content">
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                    <span className="date-of-timeline">{data.date}</span>
                                                                </ScrollAnimation>

                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                    <h2 className="title">{data.title}</h2>
                                                                </ScrollAnimation>

                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                    <p className="description">{data.description}</p>
                                                                </ScrollAnimation>

                                                                <div className="row row--30">
                                                                    {data.workingStep.map((data, index)=>(
                                                                        <div className="col-lg-6 col-md-6 col-12" key={index}>
                                                                            <div className="working-list">
                                                                                <ScrollAnimation 
                                                                                animateIn="fadeInUp"
                                                                                animateOut="fadeInOut"
                                                                                animateOnce={true}>
                                                                                    <h5 className="working-title">{data.stepTitle}</h5>
                                                                                </ScrollAnimation>

                                                                                <ScrollAnimation 
                                                                                animateIn="fadeInUp"
                                                                                animateOut="fadeInOut"
                                                                                animateOnce={true}>
                                                                                    <p>{data.stepDescription}</p>
                                                                                </ScrollAnimation>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="order-1 order-lg-2 col-lg-6">
                                                            <div className="thumbnail">
                                                                <img className="w-100" src={`./images/timeline/${data.image}.webp`} alt="Unobi technologies Private Limited" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                
                                    {/* End Single Timeline  */}
                                </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* End Timeline Area  */}
                <Separator />
                <CalltoActionSix />
  
                <FooterOne />
                <Copyright />
            </main>
        </>
    )
}

export default GraphicsDesigning
