import React from 'react';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import Copyright from '../common/footer/Copyright';
import { FiArrowRight,FiCheck } from "react-icons/fi";
import Separator from "../elements/separator/Separator"; 
import AboutTwo from "../elements/about/AboutTwo";
import AboutTwoInverted from "../elements/about/AboutTwoInverted";
import CalltoActionSix from "../elements/calltoaction/CalltoActionSix"; 
import TimelineOne from "../elements/timeline/TimelineOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import FooterOne from '../common/footer/FooterOne';
import HeaderTopBar from '../common/header/HeaderTopBar';
import useDocumentTitle from '../useDocumentTitle'; 
import ScrollAnimation from "react-animate-on-scroll";
import {Link} from "react-router-dom";
 
const TimelineData = [
    {
        id: "1",
        date: "Step-1",
        title: "Understanding the requirements: ",
        description: "Our team will conduct a thorough analysis of your business requirements and objectives to understand the scope of work.",
        image: "unobi_tech_DB_Dev_1",
        workingStep: [
             
        ]
    },
    {
        id: "2",
        date: "Step-2",
        title: "Designing the database:",
        description: "Based on the requirements, our team will design an efficient and effective database architecture.",
        image: "unobi_tech_DB_Dev_2",
        workingStep: [
            // {
            //     stepTitle: "Follow this process",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
            // {
            //     stepTitle: "Working experience",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
        ]
    },
    {
        id: "3",
        date: "Step-3",
        title: "Development:",
        description: "Our developers will then use their expertise in various database technologies to build the database.",
        image: "unobi_tech_DB_Dev_3",
        workingStep: [
            // {
            //     stepTitle: "Follow this process",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
            // {
            //     stepTitle: "Working experience",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
        ]
    },
   
    {
        id: "4",
        date: "Step-4",
        title: "Testing:",
        description: "The database will undergo rigorous testing to ensure its functionality and performance.",
        image: "unobi_tech_DB_Dev_4",
        workingStep: [
            // {
            //     stepTitle: "Follow this process",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
            // {
            //     stepTitle: "Working experience",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
        ]
    },
    {
        id: "5",
        date: "Step-5",
        title: "Deployment:",
        description: "The database will be deployed and integrated with your existing systems, ensuring seamless and hassle-free operation.",
        image: "unobi_tech_DB_Dev_5",
        workingStep: [
            // {
            //     stepTitle: "Follow this process",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
            // {
            //     stepTitle: "Working experience",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
        ]
    },
    {
        id: "6",
        date: "Step-6",
        title: "Maintenance and support:",
        description: "Our team will provide ongoing maintenance and support, including monitoring, backup, and security, to keep your database running smoothly.",
        image: "unobi_tech_DB_Dev_6",
        workingStep: [
            // {
            //     stepTitle: "Follow this process",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
            // {
            //     stepTitle: "Working experience",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
        ]
    },
]
const DataBaseDevelopment = () => {
    useDocumentTitle('Expert Database Development Services - Unobi Tech');            
    return (
        <>
            <SEO title="Expert Database Development Services - Unobi Tech" />
            <main className="page-wrapper">
                <HeaderTopBar />
                <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-6 shape-right height-750">
                    <div className="container">
                        <div className="row row--30 align-items-center">
                            <div className="order-2 order-lg-1 col-lg-6 mt_md--50 mt_sm--50 mt_lg--30">
                                <div className="inner text-start">
                                    <h1 className="title color-white">Database <br />Development</h1>
                                    <p className="description color-white">Transform your data into a competitive advantage with Unobi Tech's database development.</p>
                                    <div className="button-group mt--30 mt_sm--20">
                                        <a className="btn-default btn-medium btn-icon btn-border" target="_blank" href="/contact">Enquire Now <i className="icon"><FiArrowRight /></i></a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 order-1 order-lg-2">
                                <div className="thumbnail">
                                    <img src="./images/banner/unobi_tech_databasedevelopment_page_banner.webp" alt="Unobi technologies" />
                                </div>
                            </div>
                            <div className="shape-image">
                                <img src="./images/banner/white-shape.svg" alt="Unobi technologies" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />
                
                
                <div className="rwt-about-area about-style-2 rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/unobi_tech_databasedevelopment_page_about.webp" alt="Unobi technologies" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h4 className="subtitle"><span className="theme-gradient">About our database development.</span></h4>
                                    <h2 className="title mt--10"> Why database development is need to your business?</h2>
                                </ScrollAnimation>

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <p>Database development is an integral part of any business, as it is the backbone of the information management system. A well-designed database helps in organizing, storing, and retrieving data effectively. It plays a crucial role in managing business operations and decision making by providing real-time information to stakeholders. By having a robust database development solution, businesses can streamline their processes, improve efficiency, and reduce costs. It is a must-have for businesses that handle a large amount of data on a daily basis and want to ensure data security and accuracy.</p>

                                     
                                </ScrollAnimation>
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <div className="read-more-btn mt--40">
                                        <Link className="btn-default btn-icon" to="contact">Get More Info<i className="icon"><FiArrowRight /></i></Link>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
                <Separator />
                
                <div className="rwt-about-area about-style-2 rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                  
                <div className="col-lg-7 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h4 className="subtitle"><span className="theme-gradient">More about....</span></h4>
                                    <h2 className="title mt--10">Introduction to Unobi Tech's database development service</h2>
                                </ScrollAnimation>

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <p>Unobi Tech's database development is a comprehensive solution to businesses seeking to improve their data management systems. Our team of experts combines technical knowledge, business understanding, and project management skills to deliver customized, scalable, and secure databases that meet the specific needs of businesses of all sizes. With a deep understanding of various database management systems and tools, Unobi Tech provides reliable and efficient database design and development services, ensuring the seamless and optimized storage and retrieval of data.</p>

                                    <ul className="list-icon">
                                        <li><span className="icon"><FiCheck /></span> Empower your business</li>
                                        <li><span className="icon"><FiCheck /></span> Scalable</li>
                                        <li><span className="icon"><FiCheck /></span> Secure</li>
                                        <li><span className="icon"><FiCheck /></span> Reliable</li>
                                    </ul>
                                </ScrollAnimation>
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <div className="read-more-btn mt--40">
                                    <a className="btn-default btn-icon" href="tel:+91 63696 06204">Call Now <i className="icon"><FiArrowRight /></i></a>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                  
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/unobi_tech_databasedevelopment_page_about1.webp" alt="Unobi technologies" />
                        </div>
                    </div>

                </div>
            </div>   
        </div>
                <Separator />
                  {/* Start Timeline Area  */}
                  <div className="rwt-timeline-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            subtitle = "Database development"
                                            title = "Our development process"
                                        />
                                </div>
                            </div>

                            
                            <div className="row">
                                <div className="col-lg-12">
                                <div className="rn-timeline-wrapper timeline-style-one position-relative">
                                    <div className="timeline-line"></div>
                                    {/* Start Single Timeline  */}

                                    {TimelineData.map((data, index) =>(
                                        <div className="single-timeline mt--50" key={index}>
                                            <div className="timeline-dot">
                                                <div className="time-line-circle"></div>
                                            </div>
                                            <div className="single-content">
                                                <div className="inner">
                                                    <div className="row row--30 align-items-center">
                                                        <div className="order-2 order-lg-1 col-lg-6 mt_md--40 mt_sm--40">
                                                            <div className="content">
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                    <span className="date-of-timeline">{data.date}</span>
                                                                </ScrollAnimation>

                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                    <h2 className="title">{data.title}</h2>
                                                                </ScrollAnimation>

                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                    <p className="description">{data.description}</p>
                                                                </ScrollAnimation>

                                                                <div className="row row--30">
                                                                    {data.workingStep.map((data, index)=>(
                                                                        <div className="col-lg-6 col-md-6 col-12" key={index}>
                                                                            <div className="working-list">
                                                                                <ScrollAnimation 
                                                                                animateIn="fadeInUp"
                                                                                animateOut="fadeInOut"
                                                                                animateOnce={true}>
                                                                                    <h5 className="working-title">{data.stepTitle}</h5>
                                                                                </ScrollAnimation>

                                                                                <ScrollAnimation 
                                                                                animateIn="fadeInUp"
                                                                                animateOut="fadeInOut"
                                                                                animateOnce={true}>
                                                                                    <p>{data.stepDescription}</p>
                                                                                </ScrollAnimation>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="order-1 order-lg-2 col-lg-6">
                                                            <div className="thumbnail">
                                                                <img className="w-100" src={`./images/timeline/${data.image}.webp`} alt="Unobi technologies Private Limited" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                
                                    {/* End Single Timeline  */}
                                </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* End Timeline Area  */}

                <Separator />
                <CalltoActionSix />
  
                <FooterOne />
                <Copyright />
            </main>
        </>
    )
}

export default DataBaseDevelopment
