import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import {Link} from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";

const AboutTwoInverted = () => {
    return (
        <div className="rwt-about-area about-style-2 rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                  
                <div className="col-lg-7 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h4 className="subtitle"><span className="theme-gradient">More about....</span></h4>
                                    <h2 className="title mt--10">Introduction to Unobi Tech's web development services</h2>
                                </ScrollAnimation>

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <p>Unobi Tech is a leading provider of web development services, delivering custom solutions to businesses of all sizes. Our team of experienced developers and designers creates visually appealing and user-friendly websites, using the latest technologies and techniques. We believe in a user-centered approach, ensuring that every website we build is not only attractive but also functional and easy to use. Contact us today to learn how we can help you achieve your goals with our professional web development services.</p>

                                    <ul className="list-icon">
                                        <li><span className="icon"><FiCheck /></span> Expertise in latest technologies and frameworks</li>
                                        <li><span className="icon"><FiCheck /></span> User-friendly, responsive designs</li>
                                        <li><span className="icon"><FiCheck /></span> Custom solutions for unique business needs</li>
                                        <li><span className="icon"><FiCheck /></span>Affordable, high-quality development services</li>
                                    </ul>
                                </ScrollAnimation>
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <div className="read-more-btn mt--40">
                                        <a className="btn-default btn-icon" href="tel:+91 63696 06204">Call Now <i className="icon"><FiArrowRight /></i></a>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                  
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/unobi_tech_web_development_page_about1.webp" alt="Unobi Technologies" />
                        </div>
                    </div>

                </div>
            </div>   
        </div>
    )
}

export default AboutTwoInverted;
