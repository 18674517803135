import React from 'react';
import VideoTwo from '../video/VideoTwo';
import { FiCheck } from "react-icons/fi";


const PopupData = [
    {
        id: "01",
        image: "./images/bg/unobi_tech_contact-image.jpg",
        popupLink: [
            'https://youtu.be/BUNMtoODzX8',
        ],
    }
]

const AboutFour = ({image}) => {
    return (
        <div className="about-area about-style-4 rn-section-gap">
            <div className="container">
                <div className="row row--40 align-items-center">
                    <div className="col-lg-6">
                        {PopupData.map((item) => (
                            <div className="video-btn" key={item.id}>
                                <VideoTwo imagename={`${image}`} galleryItem={item} />
                            </div>
                        ))}
                    </div>
                    <div className="col-lg-6 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="inner">
                                <h3 className="title">Unobi Tech specializes in providing  <br /> <strong>innovative tech </strong>solutions for businesses.</h3>
                                <ul className="feature-list">
                                   <li>
                                        <div className="icon">
                                            <FiCheck />
                                        </div>
                                        <div className="title-wrapper">
                                            <h4 className="title">IoT and RPA solutions</h4>
                                            <p className="text">Unobi Tech helps businesses stay ahead of the competition by integrating IoT and RPA technology into their operations. Improve efficiency and drive growth with our cutting-edge solutions.</p>
                                        </div>
                                        
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <FiCheck />
                                        </div>
                                        <div className="title-wrapper">
                                            <h4 className="title">Custom software development</h4>
                                            <p className="text">Our team of developers creates software tailored to your business needs. We specialize in desktop software, mobile apps, and web-based solutions.</p>
                                        </div>
                                        
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <FiCheck />
                                        </div>
                                        <div className="title-wrapper">
                                            <h4 className="title">Innovative solutions for your business</h4>
                                            <p className="text">Unobi Tech creates customized solutions that meet the unique needs of your business, increasing efficiency and driving growth.</p>
                                        </div>
                                    </li>
                                </ul>
                                <div className="about-btn mt--30">
                                    <a className="btn-default" href="/about-us">About Us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutFour
