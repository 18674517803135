import React from 'react';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import Copyright from '../common/footer/Copyright';
import { FiArrowRight,FiCheck } from "react-icons/fi";
import Separator from "../elements/separator/Separator"; 
import AboutTwo from "../elements/about/AboutTwo";
import AboutTwoInverted from "../elements/about/AboutTwoInverted";
import CalltoActionSix from "../elements/calltoaction/CalltoActionSix"; 
import TimelineOne from "../elements/timeline/TimelineOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import FooterOne from '../common/footer/FooterOne';
import HeaderTopBar from '../common/header/HeaderTopBar';
import useDocumentTitle from '../useDocumentTitle'; 
import ScrollAnimation from "react-animate-on-scroll";
import {Link} from "react-router-dom";
 
const TimelineData = [
    {
        id: "1",
        date: "Step-1",
        title: "Identifying target audience: ",
        description: "The first step is to identify the target audience and the influencers that align with your brand values and target the same audience.",
        image: "unobi_tech_influencer_marketing_1",
        workingStep: [
             
        ]
    },
    {
        id: "2",
        date: "Step-2",
        title: "Finding the right influencer: ",
        description: "Once the target audience is identified, the next step is to find the right influencer that fits your brand's message and reach. This can be done through influencer marketing platforms, online research, or by reaching out to influencers directly.",
        image: "unobi_tech_influencer_marketing_2",
        workingStep: [
            // {
            //     stepTitle: "Follow this process",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
            // {
            //     stepTitle: "Working experience",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
        ]
    },
    {
        id: "3",
        date: "Step-3",
        title: "Campaign planning and negotiation: ",
        description: "After selecting the right influencer, the next step is to plan the campaign and negotiate the terms, such as compensation, content requirements, and deliverables.",
        image: "unobi_tech_influencer_marketing_3",
        workingStep: [
            // {
            //     stepTitle: "Follow this process",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
            // {
            //     stepTitle: "Working experience",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
        ]
    },
   
    {
        id: "4",
        date: "Step-4",
        title: "Campaign execution and measurement: ",
        description: "The final step is to execute the campaign and measure its success. This includes monitoring engagement and reach, analyzing the results, and making adjustments as needed to improve future campaigns.",
        image: "unobi_tech_influencer_marketing_4",
        workingStep: [
            // {
            //     stepTitle: "Follow this process",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
            // {
            //     stepTitle: "Working experience",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
        ]
    },
    {
        id: "5",
        date: "Step-5",
        title: "Evaluation and reporting: ",
        description: "After the campaign is completed, the final step is to evaluate the success of the campaign, and provide a report to stakeholders, including insights and recommendations for future campaigns.",
        image: "unobi_tech_influencer_marketing_5",
        workingStep: [
            // {
            //     stepTitle: "Follow this process",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
            // {
            //     stepTitle: "Working experience",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
        ]
    },
    
]
const InfluencerMarketing = () => {
    useDocumentTitle('Marketing Services | Boost Your Brand Visibility');    
    return (
        <>
            <SEO title="Marketing Services | Boost Your Brand Visibility" />
            <main className="page-wrapper">
                <HeaderTopBar />
                <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-6 shape-right height-750">
                    <div className="container">
                        <div className="row row--30 align-items-center">
                            <div className="order-2 order-lg-1 col-lg-6 mt_md--50 mt_sm--50 mt_lg--30">
                                <div className="inner text-start">
                                    <h1 className="title color-white">Influencer<br />Marketing</h1>
                                    <p className="description color-white">Reach your target audience with influencer marketing that works.</p>
                                    <div className="button-group mt--30 mt_sm--20">
                                        <a className="btn-default btn-medium btn-icon btn-border" target="_blank" href="/contact">Enquire Now <i className="icon"><FiArrowRight /></i></a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 order-1 order-lg-2">
                                <div className="thumbnail">
                                    <img src="./images/banner/unobi_influencer_marketing_page_banner.webp" alt="Unobi technologies" />
                                </div>
                            </div>
                            <div className="shape-image">
                                <img src="./images/banner/white-shape.svg" alt="Unobi technologies" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />
                
                
                <div className="rwt-about-area about-style-2 rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/unobi_InfluencerMarketing_page_about.webp" alt="Unobi technologies" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h4 className="subtitle"><span className="theme-gradient">About our influencer marketing.</span></h4>
                                    <h2 className="title mt--10"> Why influencer marketing is need for your business?</h2>
                                </ScrollAnimation>

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <p>Influencer marketing is important for your business because it helps you reach your target audience effectively and efficiently. By partnering with influencers who have a large following in your niche, you can tap into their network and reach potential customers that you may not have otherwise had access to. Additionally, influencer marketing provides a level of credibility and authenticity that traditional advertising cannot match. The endorsement from a trusted source can help build trust and credibility with your target audience, leading to increased conversions and sales. By leveraging the power of influencer marketing, you can grow your brand and drive real business results.</p>

                                    
                                </ScrollAnimation>
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <div className="read-more-btn mt--40">
                                        <Link className="btn-default btn-icon" to="contact">get More Info <i className="icon"><FiArrowRight /></i></Link>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
                <Separator />
                
                {/* <div className="rwt-about-area about-style-2 rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                  
                <div className="col-lg-7 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h4 className="subtitle"><span className="theme-gradient">More about....</span></h4>
                                    <h2 className="title mt--10">Introduction to Unobi Tech's app development services</h2>
                                </ScrollAnimation>

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <p>Unobi Tech is a top-notch app development company that specializes in creating custom, user-friendly, and visually appealing mobile apps for businesses of all sizes. Our team of experienced developers and designers work closely with clients to understand their unique needs and develop solutions that meet their specific requirements. We have a proven track record of delivering high-quality, scalable, and robust apps that drive business growth and provide a competitive edge. Our app development services cover the entire app development life cycle, from conceptualization and design to development, testing, and deployment. Whether you're looking to develop a native app for iOS or Android, or a cross-platform app, our team has the expertise and experience to deliver the results you need. Contact us today to learn more about how Unobi Tech can help take your business to the next level with our app development services.</p>

                                    <ul className="list-icon">
                                        <li><span className="icon"><FiCheck /></span> Boost brand awareness through Influencer campaigns.</li>
                                        <li><span className="icon"><FiCheck /></span> Connect with target audience through trusted sources.</li>
                                        <li><span className="icon"><FiCheck /></span> Increase engagement and conversions.</li>
                                        <li><span className="icon"><FiCheck /></span> Stay ahead of the competition with innovative strategies.</li>
                                    </ul>
                                </ScrollAnimation>
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <div className="read-more-btn mt--40">
                                    <a className="btn-default btn-icon" href="tel:+91 63696 06204">Call Now <i className="icon"><FiArrowRight /></i></a>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                  
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/about-1.png" alt="Unobi technologies" />
                        </div>
                    </div>

                </div>
            </div>   
        </div> */}
                <Separator />
                  {/* Start Timeline Area  */}
                  <div className="rwt-timeline-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            subtitle = "Influencer marketing"
                                            title = "Our development process"
                                        />
                                </div>
                            </div>

                            
                            <div className="row">
                                <div className="col-lg-12">
                                <div className="rn-timeline-wrapper timeline-style-one position-relative">
                                    <div className="timeline-line"></div>
                                    {/* Start Single Timeline  */}

                                    {TimelineData.map((data, index) =>(
                                        <div className="single-timeline mt--50" key={index}>
                                            <div className="timeline-dot">
                                                <div className="time-line-circle"></div>
                                            </div>
                                            <div className="single-content">
                                                <div className="inner">
                                                    <div className="row row--30 align-items-center">
                                                        <div className="order-2 order-lg-1 col-lg-6 mt_md--40 mt_sm--40">
                                                            <div className="content">
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                    <span className="date-of-timeline">{data.date}</span>
                                                                </ScrollAnimation>

                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                    <h2 className="title">{data.title}</h2>
                                                                </ScrollAnimation>

                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                    <p className="description">{data.description}</p>
                                                                </ScrollAnimation>

                                                                <div className="row row--30">
                                                                    {data.workingStep.map((data, index)=>(
                                                                        <div className="col-lg-6 col-md-6 col-12" key={index}>
                                                                            <div className="working-list">
                                                                                <ScrollAnimation 
                                                                                animateIn="fadeInUp"
                                                                                animateOut="fadeInOut"
                                                                                animateOnce={true}>
                                                                                    <h5 className="working-title">{data.stepTitle}</h5>
                                                                                </ScrollAnimation>

                                                                                <ScrollAnimation 
                                                                                animateIn="fadeInUp"
                                                                                animateOut="fadeInOut"
                                                                                animateOnce={true}>
                                                                                    <p>{data.stepDescription}</p>
                                                                                </ScrollAnimation>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="order-1 order-lg-2 col-lg-6">
                                                            <div className="thumbnail">
                                                                <img className="w-100" src={`./images/timeline/${data.image}.webp`} alt="Unobi technologies Private Limited" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                
                                    {/* End Single Timeline  */}
                                </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* End Timeline Area  */}

                <Separator />
                <CalltoActionSix />
  
                <FooterOne />
                <Copyright />
            </main>
        </>
    )
}

export default InfluencerMarketing
