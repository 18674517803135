import React from 'react'
import SEO from "../common/SEO";
import HeaderTopBar from '../common/header/HeaderTopBar';
import HeaderOne from '../common/header/HeaderOne';
import BrandThree from '../elements/brand/BrandThree';
import AboutFour from '../elements/about/AboutFour';
import ServiceOne from "../elements/service/ServiceOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import CounterUpFour from "../elements/counterup/CounterUpFour";
import TeamFour from "../elements/team/TeamFour";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Separator from "../elements/separator/Separator";
import FooterOne from '../common/footer/FooterOne';
import Copyright from '../common/footer/Copyright';
import "../styles/myStyle.css";
import BrandTwo from '../elements/brand/BrandTwo';
import useDocumentTitle from '../useDocumentTitle';

const AboutUs = () => {
    useDocumentTitle('About Unobi Tech | Leading Software Development Company');    
    return (
        <>
            <SEO title="About Unobi Tech | Leading Software Development Company" />
            <main className="page-wrapper">
            <HeaderTopBar />
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 height-850 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-12.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner pt--80 text-center">
                                    <div>
                                        <h3 className="rn-sub-badge"><span className="theme-gradient"></span></h3>
                                    </div>
                                    <h1 className="title display-one">Enhancing productivity through <br /> IT synergy</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}


                {/* Start Service Area  */}
                <div className="service-area rn-section-gapTop">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h3 className="title">Discover the expertise behind Unobi Technologies: Your trusted partner for innovation.</h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className="mb--10">Our team of experts is dedicated to helping businesses stay ahead of the curve by leveraging the latest technology trends to improve efficiency, increase revenue, and gain a competitive edge. We take a holistic approach to understanding our client's needs and tailor our solutions to meet those needs. <br/><br/>We pride ourselves on our ability to deliver high-quality work on time and within budget. Our team of experienced professionals has a wealth of knowledge and expertise in the technology field and we are committed to providing our clients with the best possible solutions.</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}



                {/* Start Brand Area  */}
                {/* <div className="rwt-brand-area pb--60 pt--30">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mt--10">
                                <BrandThree brandStyle="brand-style-2" />
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End Brand Area  */}


                <Separator />

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "What we can do for you"
                                    title = "Our Services"
                                    description = "We pride ourselves on our ability to deliver high-quality work on time and within budget. Our team of experienced professionals has a wealth of knowledge and expertise in the technology field and we are committed to providing our clients with the best possible solutions."
                                    />
                            </div>
                        </div>
                        <ServiceOne 
                            serviceStyle = "service__style--1 bg-color-blackest radius mt--25"
                            textAlign = "text-center"
                            />
                             
                    </div>
                </div>
                {/* End Service Area  */}

                <div className="rwt-timeline-area rn-section-gapBottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <TimelineTwo classVar="no-gradient"  />
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="rwt-brand-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Our expertise in the following"
                                    title = "TECHNOLOGIES"
                                    description = ""
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mt--10">
                                <BrandTwo brandStyle="brand-style-2" />
                            </div>
                        </div>
                    </div>
                </div> */}

                <Separator />

                {/* <AboutFour image="./images/about/unobi_tech_contact-image.jpg" /> */}
                
                {/* Start Elements Area  */}
                {/* <div className="rwt-counterup-area pb--100">
                    <div className="container mt_dec--30">
                        <CounterUpFour column="col-lg-3 col-md-6 col-sm-6 col-12" counterStyle="counter-style-4" textALign="text-center" />   
                    </div>
                </div> */}
                {/* End Elements Area  */}

                <Separator />

                {/* Start Elements Area  */}
                {/* <div className="rwt-team-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--20">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Our Corporate Team."
                                    title = "Corporate Team Member."
                                    description = ""
                                />
                            </div>
                        </div>
                        <TeamFour column="col-lg-6 col-xl-3 col-md-6 col-12 mt--30" teamStyle="team-style-three" />
                    </div>
                </div> */}
                {/* End Elements Area  */}

                <FooterOne /> 
                <Copyright />
            </main>
        </>
    )
}

export default AboutUs;
