import React from 'react';

const BrandList = [
    {
        image: './images/brand/unobi-tech-brand-04.png'
    },
    {
        image: './images/brand/unobi-tech-brand-11.png'
    },
    {
        image: './images/brand/unobi-tech-brand-01.png'
    },
    {
        image: './images/brand/unobi-tech-brand-09.png'
    },
    {
        image: './images/brand/unobi-tech-brand-12.png'
    },
    {
        image: './images/brand/unobi-tech-brand-07.png'
    },
    {
        image: './images/brand/unobi-tech-brand-10.png'
    },
    {
        image: './images/brand/unobi-tech-brand-02.png'
    },
    
    {
        image: './images/brand/unobi-tech-brand-03.png'
    },
    {
        image: './images/brand/unobi-tech-brand-08.png'
    },
    {
        image: './images/brand/unobi-tech-brand-06.png'
    },
    {
        image: './images/brand/unobi-tech-brand-05.png'
    },
   
    {
        image: './images/brand/unobi-tech-brand-13.png'
    },
    {
        image: './images/brand/unobi-tech-brand-14.png'
    },
    {
        image: './images/brand/unobi-tech-brand-15.png'
    },
    {
        image: './images/brand/unobi-tech-brand-16.png'
    },
    {
        image: './images/brand/unobi-tech-brand-17.png'
    },
    {
        image: './images/brand/unobi-tech-brand-18.png'
    },
]

const BrandTwo = ({brandStyle}) => {
    return (
        <ul className={`brand-list ${brandStyle}`}>
            {BrandList.map((data, index) => (
                <li key={index}>
                    <a href="#"><img src={`${data.image}`} alt="Unobi Technologies" /></a>
                </li>
            ))}
        </ul>
    )
}

export default BrandTwo;
