import React from 'react';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import Copyright from '../common/footer/Copyright';
import { FiArrowRight,FiCheck } from "react-icons/fi";
import Separator from "../elements/separator/Separator"; 
import AboutTwo from "../elements/about/AboutTwo";
import AboutTwoInverted from "../elements/about/AboutTwoInverted";
import CalltoActionSix from "../elements/calltoaction/CalltoActionSix"; 
import TimelineOne from "../elements/timeline/TimelineOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import FooterOne from '../common/footer/FooterOne';
import HeaderTopBar from '../common/header/HeaderTopBar';
import useDocumentTitle from '../useDocumentTitle'; 
import ScrollAnimation from "react-animate-on-scroll";
import {Link} from "react-router-dom";
 
const TimelineData = [
    {
        id: "1",
        date: "Step-1",
        title: "Discovery:",
        description: "We work with you to understand your business requirements and goals, and conduct user research to gain insights into the needs and behavior of your target audience.",
        image: "unobi_tech_ui_ux1",
        workingStep: [
             
        ]
    },
    {
        id: "2",
        date: "Step-2",
        title: "Conceptualization:",
        description: "Our design team creates wireframes and prototypes, exploring different design concepts and evaluating their potential.",
        image: "unobi_tech_ui_ux2",
        workingStep: [
            // {
            //     stepTitle: "Follow this process",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
            // {
            //     stepTitle: "Working experience",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
        ]
    },
    {
        id: "3",
        date: "Step-3",
        title: "Design:",
        description: "The best concepts are refined into polished visual designs, taking into account the latest design trends, best practices, and accessibility standards.",
        image: "unobi_tech_ui_ux3",
        workingStep: [
            // {
            //     stepTitle: "Follow this process",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
            // {
            //     stepTitle: "Working experience",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
        ]
    },
   
    {
        id: "4",
        date: "Step-4",
        title: "Usability testing:",
        description: "The designs are tested with real users to ensure that they are intuitive and user-friendly, and to gather feedback on any areas for improvement.",
        image: "unobi_tech_ui_ux4",
        workingStep: [
            // {
            //     stepTitle: "Follow this process",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
            // {
            //     stepTitle: "Working experience",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
        ]
    },
    {
        id: "5",
        date: "Step-5",
        title: "Implementation:",
        description: "The final designs are integrated into the product, either as a standalone application or as part of a larger development project.",
        image: "unobi_tech_ui_ux5",
        workingStep: [
            // {
            //     stepTitle: "Follow this process",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
            // {
            //     stepTitle: "Working experience",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
        ]
    },
    {
        id: "6",
        date: "Step-6",
        title: "Launch:",
        description: "The product is launched and made available to users, and our team provides ongoing support and maintenance to ensure that it continues to meet the evolving needs of your business and your users.",
        image: "unobi_tech_ui_ux6",
        workingStep: [
            // {
            //     stepTitle: "Follow this process",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
            // {
            //     stepTitle: "Working experience",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
        ]
    },
]
const UIUXDesigning = () => {
    useDocumentTitle('Professional UI/UX Design Services | Unobi Tech');    
    return (
        <>
            <SEO title="Professional UI/UX Design Services | Unobi Tech" />
            <main className="page-wrapper">
                <HeaderTopBar />
                <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-6 shape-right height-750">
                    <div className="container">
                        <div className="row row--30 align-items-center">
                            <div className="order-2 order-lg-1 col-lg-6 mt_md--50 mt_sm--50 mt_lg--30">
                                <div className="inner text-start">
                                    <h1 className="title color-white">UI/UX <br/>designing</h1>
                                    <p className="description color-white">Get user-friendly and intuitive design with Unobi Tech's UI/UX solutions.</p>
                                    <div className="button-group mt--30 mt_sm--20">
                                        <a className="btn-default btn-medium btn-icon btn-border" target="_blank" href="/contact">Enquire Now <i className="icon"><FiArrowRight /></i></a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 order-1 order-lg-2">
                                <div className="thumbnail">
                                    <img src="./images/banner/unobi_uiux_page_banner.webp" alt="Unobi technologies" />
                                </div>
                            </div>
                            <div className="shape-image">
                                <img src="./images/banner/white-shape.svg" alt="Unobi technologies" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />
                
                
                <div className="rwt-about-area about-style-2 rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/unobi_tech_uiux_page_about.webp" alt="Unobi technologies" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h4 className="subtitle"><span className="theme-gradient">About our UI/UX design.</span></h4>
                                    <h2 className="title mt--10"> Why UI/UX design?</h2>
                                </ScrollAnimation>

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <p>User satisfaction: UI/UX design helps to create a positive and enjoyable user experience, leading to higher levels of user satisfaction and positive feedback.

Increased conversion rates: A well-designed product is more likely to be used, and a great user experience can increase the chances of users taking desired actions, such as making a purchase or completing a task.

Competitive advantage: A good UI/UX design sets your product apart from the competition, making it more appealing to potential users and helping you to gain a competitive edge.

Unobi technologies: A well-designed product can enhance your Unobi Technologies, improving your company's reputation and making it easier to attract new customers.

Improved usability: A good UI/UX design makes the product easier to use, reducing the likelihood of user frustration and increasing the chances of users successfully completing their intended actions.

In short, UI/UX design plays a vital role in creating digital products that are enjoyable to use, help to achieve business goals, and improve the overall user experience.</p>

                                    
                                </ScrollAnimation>
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <div className="read-more-btn mt--40">
                                        <Link className="btn-default btn-icon" to="contact">Get More Info <i className="icon"><FiArrowRight /></i></Link>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
                <Separator />
                
                <div className="rwt-about-area about-style-2 rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                  
                <div className="col-lg-7 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h4 className="subtitle"><span className="theme-gradient">More about....</span></h4>
                                    <h2 className="title mt--10">Introduction to Unobi Tech's UI/UX designing</h2>
                                </ScrollAnimation>

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <p>Unobi Tech is a leading provider of UI/UX design services, dedicated to creating visually appealing and intuitive digital experiences for our clients. With a team of highly skilled and experienced designers, we work closely with our clients to understand their business goals and design requirements, crafting custom solutions that meet their unique needs and exceed their expectations.
Whether you're launching a new digital product, rebranding an existing one, or simply looking to improve your user experience, Unobi Tech has the expertise and knowledge to help you achieve your goals. Our designers use a user-centered approach, putting the needs of the user first and ensuring that our designs are both functional and visually appealing.
At Unobi Tech, we are committed to delivering high-quality UI/UX design services that drive results and help our clients to achieve their business objectives. Contact us today to learn more about our services and how we can help your business to succeed.
</p>

                                    <ul className="list-icon">
                                        <li><span className="icon"><FiCheck /></span> User-centered design approach.</li>
                                        <li><span className="icon"><FiCheck /></span> Interactive & engaging interfaces.</li>
                                        <li><span className="icon"><FiCheck /></span> Efficient design processes.</li>
                                        <li><span className="icon"><FiCheck /></span> Proven design results & happy clients.</li>
                                    </ul>
                                </ScrollAnimation>
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <div className="read-more-btn mt--40">
                                    <a className="btn-default btn-icon" href="tel:+91 63696 06204">Call Now <i className="icon"><FiArrowRight /></i></a>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                  
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/unobi_tech_uiux_page_about1.webp" alt="Unobi technologies" />
                        </div>
                    </div>

                </div>
            </div>   
        </div>
                <Separator />
                  {/* Start Timeline Area  */}
                  <div className="rwt-timeline-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            subtitle = "UI/UX designing"
                                            title = "Our development process"
                                        />
                                </div>
                            </div>

                            
                            <div className="row">
                                <div className="col-lg-12">
                                <div className="rn-timeline-wrapper timeline-style-one position-relative">
                                    <div className="timeline-line"></div>
                                    {/* Start Single Timeline  */}

                                    {TimelineData.map((data, index) =>(
                                        <div className="single-timeline mt--50" key={index}>
                                            <div className="timeline-dot">
                                                <div className="time-line-circle"></div>
                                            </div>
                                            <div className="single-content">
                                                <div className="inner">
                                                    <div className="row row--30 align-items-center">
                                                        <div className="order-2 order-lg-1 col-lg-6 mt_md--40 mt_sm--40">
                                                            <div className="content">
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                    <span className="date-of-timeline">{data.date}</span>
                                                                </ScrollAnimation>

                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                    <h2 className="title">{data.title}</h2>
                                                                </ScrollAnimation>

                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                    <p className="description">{data.description}</p>
                                                                </ScrollAnimation>

                                                                <div className="row row--30">
                                                                    {data.workingStep.map((data, index)=>(
                                                                        <div className="col-lg-6 col-md-6 col-12" key={index}>
                                                                            <div className="working-list">
                                                                                <ScrollAnimation 
                                                                                animateIn="fadeInUp"
                                                                                animateOut="fadeInOut"
                                                                                animateOnce={true}>
                                                                                    <h5 className="working-title">{data.stepTitle}</h5>
                                                                                </ScrollAnimation>

                                                                                <ScrollAnimation 
                                                                                animateIn="fadeInUp"
                                                                                animateOut="fadeInOut"
                                                                                animateOnce={true}>
                                                                                    <p>{data.stepDescription}</p>
                                                                                </ScrollAnimation>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="order-1 order-lg-2 col-lg-6">
                                                            <div className="thumbnail">
                                                                <img className="w-100" src={`./images/timeline/${data.image}.webp`} alt="Unobi technologies Private Limited" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                
                                    {/* End Single Timeline  */}
                                </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* End Timeline Area  */}
                <Separator />
                <CalltoActionSix />
  
                <FooterOne />
                <Copyright />
            </main>
        </>
    )
}

export default UIUXDesigning
