import React , {useState,useEffect} from 'react';
import emailjs from 'emailjs-com';
import axios from "axios";
import validator from "validator";
import './Careers.css';
import countrie from '../../data/contries.json'

function CareersForm({props , formStyle}) {
    let [name, setName] = useState("");
    let [email, setEmail] = useState("");    
    let [mobile, setMobile] = useState("");
    let [type, setType] = useState("job");
    let [role, setRole] = useState("");    
    let [file, setFile] = useState("");
    let [nameError, setNameError] = useState("");
    let [emailError, setEmailError] = useState("");
    let [phoneError, setPhoneError] = useState("");            
    let [roleError, setRoleError] = useState("");                
    let [fileError, setFileError] = useState("");
    let [countryCode, setCountryCode] = useState('');
    let [maxLength, setMaxLength] = useState(0);
  
    const validateEmail = (e) => {
        var email = e.target.value;
        if (validator.isEmail(email)) {
          setEmailError("");
        } else {
          setEmailError("Please provide valid Email.");
        }
    };    
    const validatePhone = (e) => {
    var phone = e.target.value;

    if (validator.isMobilePhone(phone) && phone.length === maxLength) {
        setPhoneError("");
    } else {
        setPhoneError("Enter valid Phone Number");
    }
    };        
    const validateName = (e) => {
    var name = e.target.value;
    if (name === '') {
        setNameError("Name is required");
    } else {
        setNameError("");
    }        
    }      
    const validateRole = (e) => {
      var name = e.target.value;
      if (name === '') {
          setRoleError("Role is required");
      } else {
          setRoleError("");
      }        
      }          
    const validateFile = (e) => {
    var file = e.target.files[0];
    if (file === '') {
        setFileError("File is required");
    } else {
        let fileName = "";          
        if(file.type == "application/pdf" && file.size <= 1e6){
        let fileToLoad = e.target.files[0];
        fileName = fileToLoad.name;
        let fileReader = new FileReader();
        fileReader.onload = function(fileLoadedEvent) {
            file = fileLoadedEvent.target.result;
            setFile(file);
        };
        fileReader.readAsDataURL(fileToLoad);            
        setFileError("");
        }
        else{
        setFileError("Upload pdf with 1 mb");            
        }
    }        
    }            
    const submitData = async () => {
        let timestamap = new Date().getTime();     
        let string = timestamap + '';   
        let formData = {
          "name" : name,
          "email" : email,
          "phone" : countryCode + mobile,
          "type" : type,
          "role" : role,
          "file" : file
        }
        if(name != '' && email != '' && mobile != '' && file != '' && role != '' && fileError === '')
        {
          const btn = document.getElementById('btn-default');
          btn.disabled = true; // Disable the button
          btn.innerHTML = '<span class="loading-icon"><i class="fas fa-spinner fa-spin"></i> Loading...</span>';
          try {
            const data = await axios
              .post(
                `https://unobitech.com/careersSubmit`,
                formData
              )
              .then((res) => {
                setName('');
                setEmail('');
                setMobile('');
                setRole('');
                setFile('');
                alert('Submitted successfully')
                const btn = document.getElementById('btn-default');
                btn.disabled = false; // Disable the button
                btn.innerHTML = 'Submit Now';
              });      
          } catch (error) {
            console.log(error);
            alert('Something wrong');
            const btn = document.getElementById('btn-default');
            btn.disabled = false; // Disable the button
            btn.innerHTML = 'Submit Now';
          }                  
        //   await db.collection("unobilabs_carriers").doc(string).set({
        //       "c_name" : name,
        //       "c_email" : email,
        //       "c_phone" :  mobile,
        //       "c_file" : file,
        //       "c_id" : timestamap,
        //   },{merge : true}).then(snap => {
        //       showToast("success", "Submitted Successfully");
        //       setName("");
        //       setEmail("");
        //       setMobile("");
        //       setFile("");    
        //   }).catch(err => {
        //       showToast("failed", "Something wrong");
        //   })
        }
        else{
          if(name == ""){
            setNameError("Name is required");
          }          
          if(email == ""){
            setEmailError("Email is required");
          }                    
          if(mobile == ""){
            setPhoneError("Mobile no is required");
          }                              
          if(role == ""){
            setRoleError("Role is required");
          }                                        
          if(file == ""){
            setFileError("File is required");
          }                                        
          if(fileError !== ""){
          }                                                  
        }
    }
     const GetGeoInfo = () => {
      return axios
        .get("https://ipapi.co/json/")
        .then((response) => {
          let data = response.data;
          setCountryCode(data.country_name);
          let lent = countrie.find(country => country.name === data.country_name);
          setMaxLength(lent.max_length);
        })
        .catch((error) => {
          console.error(error);
        });
    };
    GetGeoInfo();

    return (
        <>
            <form className={`${formStyle}`}>
                <div className="form-group">
                    <input 
                    type="text"
                    name="name"
                    value={name}
                    onChange={(e) => (setName(e.target.value),validateName(e))}
                    placeholder="Your Name"
                    required
                    />
                    <span
                            style={
                              nameError === ""
                                ? {
                                    fontFamily: "unset",
                                    fontWeight: "600",
                                    color: "#FFFFFF",
                                    fontSize: "14px",
                                  }
                                : {
                                    fontFamily: "unset",
                                    fontWeight: "400",
                                    color: "#E11B2F",
                                    fontSize: "14px",
                                  }
                            }
                          >
                            {nameError}
                    </span>                                        
                </div>

                <div className="form-group">
                    <input 
                    type="email"
                    name='email'
                    value={email}
                    onChange={(e) => (setEmail(e.target.value), validateEmail(e))}
                    placeholder="Email Address"
                    required
                    />
                        <span
                            style={
                              emailError === ""
                                ? {
                                    fontFamily: "unset",
                                    fontWeight: "600",
                                    color: "#FFFFFF",
                                    fontSize: "14px",
                                  }
                                : {
                                    fontFamily: "unset",
                                    fontWeight: "400",
                                    color: "#E11B2F",
                                    fontSize: "14px",
                                  }
                            }
                          >
                            {emailError}
                        </span>                    
                </div>

                <div className="form-group">
                    <input 
                    type="text"
                    name='phone'
                    value={mobile}
                    onChange={(e) => (setMobile(e.target.value), validatePhone(e))}
                    placeholder="Phone Number"
                    required
                    />
                        <span
                            style={
                              phoneError === ""
                                ? {
                                    fontFamily: "unset",
                                    fontWeight: "600",
                                    color: "#FFFFFF",
                                    fontSize: "14px",
                                  }
                                : {
                                    fontFamily: "unset",
                                    fontWeight: "400",
                                    color: "#E11B2F",
                                    fontSize: "14px",
                                  }
                            }
                          >
                            {phoneError}
                      </span>                    
                </div>


                <div className="form-group">
                    <label>Apply for</label>
                    <select onChange={(e) => (setType(e.target.value))}>
                        <option value="job">Job</option>
                        <option value="internship">Internship</option>
                    </select>
                </div>
                <div className="form-group">
                    <input 
                    type="text"
                    name='role'
                    value={role}
                    onChange={(e) => (setRole(e.target.value), validateRole(e))}
                    placeholder="Role"
                    required
                    />
                        <span
                            style={
                              roleError === ""
                                ? {
                                    fontFamily: "unset",
                                    fontWeight: "600",
                                    color: "#FFFFFF",
                                    fontSize: "14px",
                                  }
                                : {
                                    fontFamily: "unset",
                                    fontWeight: "400",
                                    color: "#E11B2F",
                                    fontSize: "14px",
                                  }
                            }
                          >
                            {roleError}
                      </span>                    
                </div>

                <div className="form-group">
                  <div className="custom-file">
                  <label className="custom-file-label" for="inputGroupFile01">Upload Resume</label>
                    <input type="file" className="custom-file-input" id="inputGroupFile01" accept="application/pdf"
                      aria-describedby="inputGroupFileAddon01" onChange={(event) => {
                      setFile(event.target.files[0]);
                      validateFile(event);
                    }}/>
                  </div>
                </div>
                <div className="form-group">
                        <span
                            style={
                              fileError === ""
                                ? {
                                    fontFamily: "unset",
                                    fontWeight: "600",
                                    color: "#FFFFFF",
                                    fontSize: "14px",
                                  }
                                : {
                                    fontFamily: "unset",
                                    fontWeight: "400",
                                    color: "#E11B2F",
                                    fontSize: "14px",
                                  }
                            }
                          >
                            {fileError}
                          </span>                                        
                </div>

                <div className="form-group">
                    <button id="btn-default" className="btn-default btn-large" type='button' onClick={submitData}>Submit Now</button>
                </div> 

            </form>
            <div id="snackbar" style={{"background-color": "#333"}}></div>     
        </>
    )
}
export default CareersForm;
