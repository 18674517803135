import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import PageScrollTop from './components/pageToTop/PageScrollTop'

// Pages import Here 
import Splash from "./pages/Splash";
import Consulting from "./pages/Consulting";
import WebDevelopment from "./pages/WebDevelopment";
import AppDevelopment from './pages/AppDevelopment'; 
import DesktopAppDevelopment from './pages/DesktopAppDevelopment';
import IoT from './pages/IoT';
import Automation from './pages/Automation';
import GraphicsDesigning from './pages/GraphicsDesigning';
import UIUXDesigning from './pages/UIUXDesigning';
import PCBDesigning from './pages/PCBDesigning';
import PhotoVideoGraphy from './pages/PhotoVideoGraphy';
import VideoEditing from './pages/VideoEditing';
import DigitalMarketing from './pages/DigitalMarketing';
import InfluencerMarketing from './pages/InfluencerMarketing';
import SearchEngineOptimize from './pages/SearchEngineOptimize';
import TalentSourcing from './pages/TalentSourcing';
import DataBaseDevelopment from './pages/DataBaseDevelopment';
import DataAnalysis from './pages/DataAnalysis';
import AboutUs from "./pages/AboutUs";
import Careers from './pages/Careers';

// Elements import Here 

import Progressbar from "./elements/progressbar/Progressbar";

import portfolioThreeColumn from "./elements/portfolio/PortfolioThreeColumn";
import Gallery from "./elements/gallery/Gallery";
import Contact from "./elements/contact/Contact";

import Error from "./pages/Error";


// Import Css Here 
import './assets/scss/style.scss';
import StaffPage from './pages/StaffPage';
// import Automation from './pages/Automation';
// import GraphicsDesigning from './pages/GraphicsDesigning';


const App = () => {
    return (
        <Router>
            <PageScrollTop>
                <Switch>
                    <Route path={`${process.env.PUBLIC_URL + "/"}`} exact component={Consulting}/>
                    {/* <Route path={`${process.env.PUBLIC_URL + "/business-consulting"}`} exact component={HomeDefault}/>
                    <Route path={`${process.env.PUBLIC_URL + "/business-consulting-2"}`} exact component={BusinessConsulting2}/>
                    <Route path={`${process.env.PUBLIC_URL + "/corporate"}`} exact component={Corporate}/>
                    <Route path={`${process.env.PUBLIC_URL + "/business"}`} exact component={Business}/>
                    <Route path={`${process.env.PUBLIC_URL + "/digital-agency"}`} exact component={DigitalAgency}/>
                    <Route path={`${process.env.PUBLIC_URL + "/finance"}`} exact component={Finance}/>
                    <Route path={`${process.env.PUBLIC_URL + "/company"}`} exact component={Company}/>
                    <Route path={`${process.env.PUBLIC_URL + "/marketing"}`} exact component={Marketing}/>
                    <Route path={`${process.env.PUBLIC_URL + "/travel-agency"}`} exact component={TravelAgency}/>
                    <Route path={`${process.env.PUBLIC_URL + "/consulting"}`} exact component={Consulting}/> */}
                    <Route path={`${process.env.PUBLIC_URL + "/web-development"}`} exact component={WebDevelopment}/>
                    <Route path={`${process.env.PUBLIC_URL + "/app-development"}`} exact component={AppDevelopment}/>
                    <Route path={`${process.env.PUBLIC_URL + "/desktop-app-development"}`} exact component={DesktopAppDevelopment}/>
                    <Route path={`${process.env.PUBLIC_URL + "/internet-of-things"}`} exact component={IoT}/>
                    <Route path={`${process.env.PUBLIC_URL + "/automation-rpa"}`} exact component={Automation}/>
                    <Route path={`${process.env.PUBLIC_URL + "/graphics-designing"}`} exact component={GraphicsDesigning}/>
                    <Route path={`${process.env.PUBLIC_URL + "/ui-ux-designing"}`} exact component={UIUXDesigning}/>
                    <Route path={`${process.env.PUBLIC_URL + "/pcb-designing"}`} exact component={PCBDesigning}/>
                    <Route path={`${process.env.PUBLIC_URL + "/photo-video-graphy"}`} exact component={PhotoVideoGraphy}/>
                    <Route path={`${process.env.PUBLIC_URL + "/video-editing"}`} exact component={VideoEditing}/>
                    <Route path={`${process.env.PUBLIC_URL + "/digital-marketing"}`} exact component={DigitalMarketing}/>
                    <Route path={`${process.env.PUBLIC_URL + "/influencer-marketing"}`} exact component={InfluencerMarketing}/>
                    <Route path={`${process.env.PUBLIC_URL + "/seo"}`} exact component={SearchEngineOptimize}/>
                    <Route path={`${process.env.PUBLIC_URL + "/talent-sourcing"}`} exact component={TalentSourcing}/>
                    <Route path={`${process.env.PUBLIC_URL + "/database-development"}`} exact component={DataBaseDevelopment}/>
                    <Route path={`${process.env.PUBLIC_URL + "/data-analysis"}`} exact component={DataAnalysis}/>

                    {/* <Route path={`${process.env.PUBLIC_URL + "/seo-agency"}`} exact component={SeoAgency}/>
                    <Route path={`${process.env.PUBLIC_URL + "/personal-portfolio"}`} exact component={PersonalPortfolio}/>
                    <Route path={`${process.env.PUBLIC_URL + "/event-conference"}`} exact component={EventConference}/>
                    <Route path={`${process.env.PUBLIC_URL + "/creative-portfolio"}`} exact component={CreativePortfolio}/>
                    <Route path={`${process.env.PUBLIC_URL + "/freelancer"}`} exact component={Freelancer}/>
                    <Route path={`${process.env.PUBLIC_URL + "/international-consulting"}`} exact component={InternationalConsulting}/>
                    <Route path={`${process.env.PUBLIC_URL + "/startup"}`} exact component={Startup}/>
                    <Route path={`${process.env.PUBLIC_URL + "/web-agency"}`} exact component={WebAgency}/> */}

                    {/* Blog Part  */}
                    {/* <Route path={`${process.env.PUBLIC_URL + "/blog-grid"}`} exact component={BlogGridView}/>
                    <Route path={`${process.env.PUBLIC_URL + "/blog-list-view"}`} exact component={BlogListView}/>
                    <Route path={`${process.env.PUBLIC_URL + "/blog-grid-sidebar"}`} exact component={BlogGridSidebar}/>
                    <Route path={`${process.env.PUBLIC_URL + "/blog-list-sidebar"}`} exact component={BlogListSidebar}/>
                    <Route path={`${process.env.PUBLIC_URL + "/blog-details/:id"}`} exact component={BlogDetails}/>
                    <Route path={`${process.env.PUBLIC_URL + "/category/:slug"}`} exact component={CategoryList}/>
                    <Route path={`${process.env.PUBLIC_URL + "/archive/:slug"}`} exact component={Author}/>
                    <Route path={`${process.env.PUBLIC_URL + "/tag/:slug"}`} exact component={TagList}/> */}

                    {/* <Route path={`${process.env.PUBLIC_URL + "/elements"}`} exact component={Elements}/>
                    <Route path={`${process.env.PUBLIC_URL + "/button"}`} exact component={Button}/>
                    <Route path={`${process.env.PUBLIC_URL + "/service"}`} exact component={Service}/>
                    <Route path={`${process.env.PUBLIC_URL + "/counter"}`} exact component={CounterUp}/>
                    <Route path={`${process.env.PUBLIC_URL + "/progressbar"}`} exact component={Progressbar}/>
                    <Route path={`${process.env.PUBLIC_URL + "/social-share"}`} exact component={Social}/>
                    <Route path={`${process.env.PUBLIC_URL + "/team"}`} exact component={Team}/>
                    <Route path={`${process.env.PUBLIC_URL + "/testimonial"}`} exact component={Testimonial}/>
                    <Route path={`${process.env.PUBLIC_URL + "/timeline"}`} exact component={Timeline}/>
                    <Route path={`${process.env.PUBLIC_URL + "/accordion"}`} exact component={RnAccordion}/>
                    <Route path={`${process.env.PUBLIC_URL + "/tab"}`} exact component={Tab}/>
                    <Route path={`${process.env.PUBLIC_URL + "/pricing"}`} exact component={Pricing}/>
                    <Route path={`${process.env.PUBLIC_URL + "/split"}`} exact component={Split}/>
                    <Route path={`${process.env.PUBLIC_URL + "/call-to-action"}`} exact component={CalltoAction}/>
                    <Route path={`${process.env.PUBLIC_URL + "/video-popup"}`} exact component={Video}/>
                    <Route path={`${process.env.PUBLIC_URL + "/gallery"}`} exact component={Gallery}/>
                    <Route path={`${process.env.PUBLIC_URL + "/brand"}`} exact component={Brand}/>
                    <Route path={`${process.env.PUBLIC_URL + "/advance-tab"}`} exact component={AdvanceTab}/>
                    <Route path={`${process.env.PUBLIC_URL + "/advance-pricing"}`} exact component={AdvancePricing}/> */}
                    <Route path={`${process.env.PUBLIC_URL + "/about-us"}`} exact component={AboutUs}/>
                    <Route path={`${process.env.PUBLIC_URL + "/contact"}`} exact component={Contact}/>
                    <Route path={`${process.env.PUBLIC_URL + "/careers"}`} exact component={Careers}/>
                    
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio"}`} exact component={portfolioThreeColumn}/>
                    <Route path={`${process.env.PUBLIC_URL + "/:id"}`} exact component={StaffPage}/>                    
                    {/* <Route path={`${process.env.PUBLIC_URL + "/*"}`} exact component={Error}/> */}

                    {/* <Route path={`${process.env.PUBLIC_URL + "/portfolio-three-column"}`} exact component={portfolioThreeColumn}/>
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio-full-width"}`} exact component={PortfolioFullWidth}/>
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio-grid-layout"}`} exact component={PortfolioGrid}/>
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio-box-layout"}`} exact component={PortfolioBoxLayout}/>
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio-details/:id"}`} exact component={PortfolioDetails}/> */}


                </Switch>
            </PageScrollTop>
        </Router>
    )
}


export default App
