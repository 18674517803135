import React from 'react';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import Copyright from '../common/footer/Copyright';
import { FiArrowRight } from "react-icons/fi";
import Separator from "../elements/separator/Separator"; 
import AboutTwo from "../elements/about/AboutTwo";
import AboutTwoInverted from "../elements/about/AboutTwoInverted";
import CalltoActionSix from "../elements/calltoaction/CalltoActionSix"; 
import TimelineOne from "../elements/timeline/TimelineOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import FooterOne from '../common/footer/FooterOne';
import HeaderTopBar from '../common/header/HeaderTopBar';
import useDocumentTitle from '../useDocumentTitle';

const WebDevelopment = () => {
    useDocumentTitle('Web Development Services - Innovative Solutions | Unobi Tech');    
    return (
        <>
            <SEO title="Web Development Services - Innovative Solutions | Unobi Tech" />
            <main className="page-wrapper">
                <HeaderTopBar />
                <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-6 shape-right height-750">
                    <div className="container">
                        <div className="row row--30 align-items-center">
                            <div className="order-2 order-lg-1 col-lg-6 mt_md--50 mt_sm--50 mt_lg--30">
                                <div className="inner text-start">
                                    <h1 className="title color-white">Web <br />Development</h1>
                                    <p className="description color-white">Get a website that's as unique as your business, with Unobi Tech</p>
                                    <div className="button-group mt--30 mt_sm--20">
                                        <a className="btn-default btn-medium btn-icon btn-border" target="_blank" href="/contact">Enquire Now <i className="icon"><FiArrowRight /></i></a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 order-1 order-lg-2">
                                <div className="thumbnail">
                                    <img src="./images/banner/web_development_page_banner.webp" alt="Unobi Technologies" />
                                </div>
                            </div>
                            <div className="shape-image">
                                <img src="./images/banner/white-shape.svg" alt="Unobi Technologies" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />
                
                
                <AboutTwo />
                <Separator />
                
                <AboutTwoInverted />
                <Separator />
                  {/* Start Timeline Area  */}
                  <div className="rwt-timeline-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            subtitle = "Web Development"
                                            title = "Our development process"
                                        />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-12">
                                    <TimelineOne />
                                </div>
                            </div>

                            
                        </div>
                    </div>
                    {/* End Timeline Area  */}

                <Separator />
                <CalltoActionSix />
  
                <FooterOne />
                <Copyright />
            </main>
        </>
    )
}

export default WebDevelopment
