import React , {useState} from 'react';
import emailjs from 'emailjs-com';
import axios from "axios";

const Result = () => {
    return (
        <p className="success-message">Your Message has been successfully sent. Our team will contact you soon.</p>
    )
}

function ContactForm({props , formStyle}) {
    const [ result,showresult ] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        subject: '',
        query: '',                
      });
    
      const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      };
    

    const sendEmail = async (e) => {
        e.preventDefault();
        try {
            const btn = document.getElementById('btn-default');
            btn.disabled = true; // Disable the button
            btn.innerHTML = '<span class="loading-icon"><i class="fas fa-spinner fa-spin"></i> Loading...</span>';            
            const data = await axios
              .post(
                `https://unobitech.com/contactSubmit`,
                formData
              )
              .then((res) => {
                alert("Submitted successfully");
                setFormData({
                    name: '',
                    phone: '',
                    email: '',
                    subject: '',
                    query: '',                
                  })          
                  const btn = document.getElementById('btn-default');
                  btn.disabled = false; // Disable the button
                  btn.innerHTML = 'Submit Now';  
              });      
          } catch (error) {
            alert('Something wrong');
            const btn = document.getElementById('btn-default');
            btn.disabled = false; // Disable the button
            btn.innerHTML = 'Submit Now';
          }        
        e.target.reset();
        showresult(true);
    };

    setTimeout(() => {
        showresult(false);
    }, 5000);

    return (
        <>
            <form className={`${formStyle}`} action="" onSubmit={sendEmail}>
                <div className="form-group">
                    <input 
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Your Name"
                    required
                    />
                </div>

                <div className="form-group">
                    <input 
                    type="email"
                    name='email'
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Email Address"
                    required
                    />
                </div>

                <div className="form-group">
                    <input 
                    type="text"
                    name='phone'
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="Phone Number"
                    required
                    />
                </div>


                <div className="form-group">
                    <input 
                    type="text"
                    name='subject'
                    value={formData.subject}
                    onChange={handleChange}
                    placeholder="Subject"
                    required
                    />
                </div>

                <div className="form-group">
                    <textarea 
                    name='query'
                    value={formData.query}
                    onChange={handleChange}
                    placeholder="Your Message"
                    required
                    >
                    </textarea>
                </div>

                <div className="form-group">
                    <button id='btn-default' className="btn-default btn-large">Submit Now</button>
                </div> 

                <div className="form-group">
                    {result ? <Result /> : null}
                </div> 
            </form>
        </>
    )
}
export default ContactForm;
