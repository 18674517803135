import PropTypes from "prop-types";
import React from 'react'


const SEO = ( {title} ) => {
    return (
        <>
            <meta charSet="utf-8" />
            <title>{title}</title>
            <meta name="robots" content="noindex, follow" />
        </>
    )
}
SEO.propTypes = {
    title: PropTypes.string
};

export default SEO;


