import React from 'react';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import Copyright from '../common/footer/Copyright';
import { FiArrowRight,FiCheck } from "react-icons/fi";
import Separator from "../elements/separator/Separator"; 
import AboutTwo from "../elements/about/AboutTwo";
import AboutTwoInverted from "../elements/about/AboutTwoInverted";
import CalltoActionSix from "../elements/calltoaction/CalltoActionSix"; 
import TimelineOne from "../elements/timeline/TimelineOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import FooterOne from '../common/footer/FooterOne';
import HeaderTopBar from '../common/header/HeaderTopBar';
import useDocumentTitle from '../useDocumentTitle'; 
import ScrollAnimation from "react-animate-on-scroll";
import {Link} from "react-router-dom";
 
const TimelineData = [
    {
        id: "1",
        date: "Step-1",
        title: "Research:",
        description: "In this step, you gather information and data to understand your target audience, market trends, and competition. This step helps you identify opportunities and make informed decisions",
        image: "unobi_tech_digital_marketing1",
        workingStep: [
             
        ]
    },
    {
        id: "2",
        date: "Step-2",
        title: "Create:",
        description: "Based on the information gathered in the research step, you create your marketing strategy and content, including the design of your website, the creation of your social media accounts, and the production of advertisements.",
        image: "unobi_tech_digital_marketing2",
        workingStep: [
            // {
            //     stepTitle: "Follow this process",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
            // {
            //     stepTitle: "Working experience",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
        ]
    },
    {
        id: "3",
        date: "Step-3",
        title: "Promote:",
        description: "This step involves executing your marketing plan and promoting your products/services. You can use various channels such as social media, email marketing, and paid advertising to reach your target audience.",
        image: "unobi_tech_digital_marketing3",
        workingStep: [
            // {
            //     stepTitle: "Follow this process",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
            // {
            //     stepTitle: "Working experience",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
        ]
    },
   
    {
        id: "4",
        date: "Step-4",
        title: "Analyze:",
        description: "This step involves tracking and measuring the results of your marketing efforts to see how well your strategies are performing. You can use tools like Google Analytics, web tracking, and A/B testing to measure key performance indicators like website traffic, conversion rates, and return on investment.",
        image: "unobi_tech_digital_marketing4",
        workingStep: [
            // {
            //     stepTitle: "Follow this process",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
            // {
            //     stepTitle: "Working experience",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
        ]
    },
    {
        id: "5",
        date: "Step-5",
        title: "Optimize:",
        description: "Based on the data and insights you gather in the analysis step, you can optimize your marketing strategies to improve performance and achieve better results. This may involve making changes to your website, adjusting your advertising campaigns, or testing new approaches. The goal of optimization is to continuously improve your marketing efforts and achieve your business goals.",
        image: "unobi_tech_digital_marketing5",
        workingStep: [
            // {
            //     stepTitle: "Follow this process",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
            // {
            //     stepTitle: "Working experience",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
        ]
    },
    
]
const DigitalMarketing = () => {
    useDocumentTitle('Expert Digital Marketing Services - Boost Your Online Presence with Unobi Tech');    
    return (
        <>
            <SEO title="Expert Digital Marketing Services - Boost Your Online Presence with Unobi Tech" />
            <main className="page-wrapper">
                <HeaderTopBar />
                <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-6 shape-right height-750">
                    <div className="container">
                        <div className="row row--30 align-items-center">
                            <div className="order-2 order-lg-1 col-lg-6 mt_md--50 mt_sm--50 mt_lg--30">
                                <div className="inner text-start">
                                    <h1 className="title color-white">Digital <br/>Marketing</h1>
                                    <p className="description color-white">Maximize your reach and drive results with Unobi Tech's digital marketing services</p>
                                    <div className="button-group mt--30 mt_sm--20">
                                        <a className="btn-default btn-medium btn-icon btn-border" target="_blank" href="/contact">Enquire Now <i className="icon"><FiArrowRight /></i></a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 order-1 order-lg-2">
                                <div className="thumbnail">
                                    <img src="./images/banner/unobi_tech_digital_marketing_page_banner.webp" alt="Unobi technologies" />
                                </div>
                            </div>
                            <div className="shape-image">
                                <img src="./images/banner/white-shape.svg" alt="Unobi technologies" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />
                
                
                <div className="rwt-about-area about-style-2 rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/unobi_tech_digitalMarketing_page_about.webp" alt="Unobi technologies" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h4 className="subtitle"><span className="theme-gradient">About our digital marketing.</span></h4>
                                    <h2 className="title mt--10"> Why digital marketing is need for bussiness?</h2>
                                </ScrollAnimation>

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <p>Digital marketing is an essential aspect of any modern business strategy. It allows companies to reach a large and targeted audience through various digital channels, including social media, email, search engines, and more. By using digital marketing techniques, businesses can improve their brand visibility, increase their customer base, and generate more leads and sales. In today's fast-paced and highly competitive business environment, having a strong digital presence is crucial for success. That's why digital marketing is a must for businesses looking to stay ahead of the curve and reach their full potential.</p>

                                   
                                </ScrollAnimation>
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <div className="read-more-btn mt--40">
                                        <Link className="btn-default btn-icon" to="contact">Get More Info <i className="icon"><FiArrowRight /></i></Link>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
                <Separator />
                
                <div className="rwt-about-area about-style-2 rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                  
                <div className="col-lg-7 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h4 className="subtitle"><span className="theme-gradient">More about....</span></h4>
                                    <h2 className="title mt--10">Introduction to Unobi Tech's digital marketing</h2>
                                </ScrollAnimation>

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <p>Unobi Tech provides comprehensive digital marketing services that are designed to help businesses reach their target audience and grow online. With a team of experienced professionals and the latest tools and techniques, Unobi Tech helps companies create and execute effective digital marketing strategies that drive results. From search engine optimization and social media marketing, to email marketing and paid advertising, our digital marketing experts will work with you to create a customized plan that fits your business needs and goals.</p>

                                    <ul className="list-icon">
                                        <li><span className="icon"><FiCheck /></span> Drive traffic, leads and sales through effective digital campaign.</li>
                                        <li><span className="icon"><FiCheck /></span> Enhance brand visibility and reputation.</li>
                                        <li><span className="icon"><FiCheck /></span> Target specific audience through personalized strategies.</li>
                                        <li><span className="icon"><FiCheck /></span> Drive targeted traffic.</li>
                                    </ul>
                                </ScrollAnimation>
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <div className="read-more-btn mt--40">
                                    <a className="btn-default btn-icon" href="tel:+91 63696 06204">Call Now <i className="icon"><FiArrowRight /></i></a>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                  
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/unobi_tech_digitalMarketing_page_about1.webp" alt="Unobi technologies" />
                        </div>
                    </div>

                </div>
            </div>   
        </div>
                <Separator />
                  {/* Start Timeline Area  */}
                  <div className="rwt-timeline-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            subtitle = "Digital marketing"
                                            title = "Our development process"
                                        />
                                </div>
                            </div>

                            
                            <div className="row">
                                <div className="col-lg-12">
                                <div className="rn-timeline-wrapper timeline-style-one position-relative">
                                    <div className="timeline-line"></div>
                                    {/* Start Single Timeline  */}

                                    {TimelineData.map((data, index) =>(
                                        <div className="single-timeline mt--50" key={index}>
                                            <div className="timeline-dot">
                                                <div className="time-line-circle"></div>
                                            </div>
                                            <div className="single-content">
                                                <div className="inner">
                                                    <div className="row row--30 align-items-center">
                                                        <div className="order-2 order-lg-1 col-lg-6 mt_md--40 mt_sm--40">
                                                            <div className="content">
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                    <span className="date-of-timeline">{data.date}</span>
                                                                </ScrollAnimation>

                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                    <h2 className="title">{data.title}</h2>
                                                                </ScrollAnimation>

                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                    <p className="description">{data.description}</p>
                                                                </ScrollAnimation>

                                                                <div className="row row--30">
                                                                    {data.workingStep.map((data, index)=>(
                                                                        <div className="col-lg-6 col-md-6 col-12" key={index}>
                                                                            <div className="working-list">
                                                                                <ScrollAnimation 
                                                                                animateIn="fadeInUp"
                                                                                animateOut="fadeInOut"
                                                                                animateOnce={true}>
                                                                                    <h5 className="working-title">{data.stepTitle}</h5>
                                                                                </ScrollAnimation>

                                                                                <ScrollAnimation 
                                                                                animateIn="fadeInUp"
                                                                                animateOut="fadeInOut"
                                                                                animateOnce={true}>
                                                                                    <p>{data.stepDescription}</p>
                                                                                </ScrollAnimation>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="order-1 order-lg-2 col-lg-6">
                                                            <div className="thumbnail">
                                                                <img className="w-100" src={`./images/timeline/${data.image}.webp`} alt="Unobi technologies Private Limited" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                
                                    {/* End Single Timeline  */}
                                </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* End Timeline Area  */}

                <Separator />
                <CalltoActionSix />
  
                <FooterOne />
                <Copyright />
            </main>
        </>
    )
}

export default DigitalMarketing
