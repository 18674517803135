import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";

const TimelineData = [
    {
        id: "1",
        date: "Step-1",
        title: "Requirements gathering and analysis:",
        description: "This is the first step in the web development process, where the developer works with the client to understand their needs and requirements. This includes evaluating the client's goals, target audience, and desired features and functionality for the website.",
        image: "unobi-tech-step-web1",
        workingStep: [
            // {
            //     stepTitle: "Gain your works",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
            // {
            //     stepTitle: "Working process",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
        ]
    },
    {
        id: "2",
        date: "Step-2",
        title: "Design and prototyping:",
        description: "Based on the requirements gathered, the developer will create a design for the website, which includes the layout, color scheme, and other visual elements. A prototype or mock-up of the website is created, which provides an early preview of what the final website will look like.",
        image: "unobi-tech-step-web2",
        workingStep: [
            // {
            //     stepTitle: "Follow this process",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
            // {
            //     stepTitle: "Working experience",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
        ]
    },
    {
        id: "3",
        date: "Step-3",
        title: "Development:",
        description: "During this stage, the developer will build the website, using the design and prototype as a guide. This includes coding the website using HTML, CSS, JavaScript, and other web development technologies. The developer may also integrate databases, APIs, and other functionality into the website.",
        image: "unobi-tech-step-web3",
        workingStep: [
           
        ]
    },
    {
        id: "4",
        date: "Step-4",
        title: "Testing and quality assurance: ",
        description: "Before launching the website, it's important to thoroughly test it to ensure that it meets all the requirements and is free of bugs and other issues. The developer will perform functional, security, and performance testing, and address any issues that are identified.",
        image: "unobi-tech-step-web4",
        workingStep: [
           
        ]
    },
    {
        id: "5",
        date: "Step-5",
        title: "Launch:",
        description: "Once the website has been tested and all issues have been resolved, the developer will launch the website and make it publicly accessible.",
        image: "unobi-tech-step-web5",
        workingStep: [
           
        ]
    },
    {
        id: "6",
        date: "Step-6",
        title: "Maintenance:",
        description: "After the website has been launched, the developer will provide ongoing maintenance and support to ensure that the website continues to function optimally and meet the client's needs.",
        image: "unobi-tech-step-web6",
        workingStep: [
            
        ]
    },
]

const TimelineOne = () => {
    return (
        <div className="rn-timeline-wrapper timeline-style-one position-relative">
            <div className="timeline-line"></div>
            {/* Start Single Timeline  */}

            {TimelineData.map((data, index) =>(
                <div className="single-timeline mt--50" key={index}>
                    <div className="timeline-dot">
                        <div className="time-line-circle"></div>
                    </div>
                    <div className="single-content">
                        <div className="inner">
                            <div className="row row--30 align-items-center">
                                <div className="order-2 order-lg-1 col-lg-6 mt_md--40 mt_sm--40">
                                    <div className="content">
                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <span className="date-of-timeline">{data.date}</span>
                                        </ScrollAnimation>

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <h2 className="title">{data.title}</h2>
                                        </ScrollAnimation>

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <p className="description">{data.description}</p>
                                        </ScrollAnimation>

                                        <div className="row row--30">
                                            {data.workingStep.map((data, index)=>(
                                                <div className="col-lg-6 col-md-6 col-12" key={index}>
                                                    <div className="working-list">
                                                        <ScrollAnimation 
                                                        animateIn="fadeInUp"
                                                        animateOut="fadeInOut"
                                                        animateOnce={true}>
                                                            <h5 className="working-title">{data.stepTitle}</h5>
                                                        </ScrollAnimation>

                                                        <ScrollAnimation 
                                                        animateIn="fadeInUp"
                                                        animateOut="fadeInOut"
                                                        animateOnce={true}>
                                                            <p>{data.stepDescription}</p>
                                                        </ScrollAnimation>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        {/* <div className="read-morebtn">
                                            <ScrollAnimation 
                                            animateIn="fadeInUp"
                                            animateOut="fadeInOut"
                                            animateOnce={true}>
                                                <a className="btn-default btn-large round" href="/contact"><span>Get Started Now</span></a>
                                            </ScrollAnimation>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="order-1 order-lg-2 col-lg-6">
                                    <div className="thumbnail">
                                        <img className="w-100" src={`./images/timeline/${data.image}.webp`} alt="Unobi Technologies Private Limited" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
           
            {/* End Single Timeline  */}
        </div>
    )
}

export default TimelineOne;
