import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import {Link} from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";

const AboutTwo = () => {
    return (
        <div className="rwt-about-area about-style-2 rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/unobi_tech_web_development_page_about.webp" alt="Unobi Technologies" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h4 className="subtitle"><span className="theme-gradient">About our web development.</span></h4>
                                    <h2 className="title mt--10"> Why our web development service is important for business?</h2>
                                </ScrollAnimation>

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <p>In today's digital age, having a strong online presence is essential for businesses of all sizes. A well-designed website is a critical component of this online presence, and web development services are key to creating and maintaining a website that is visually appealing, user-friendly, and optimized for search engines.</p>

                                    <ul className="list-icon">
                                        <li><span className="icon"><FiCheck /></span> Increased visibility</li>
                                        <li><span className="icon"><FiCheck /></span> Better user experience</li>
                                        <li><span className="icon"><FiCheck /></span> Improved branding</li>
                                        <li><span className="icon"><FiCheck /></span> Increased sales and revenue</li>
                                    </ul>
                                    <p>At our web development company, we understand the importance of a strong online presence for businesses. That's why we offer comprehensive web development services that are tailored to the unique needs of each client. Whether you are looking to create a new website from scratch or update an existing site, we can help you achieve your goals and grow your business online.</p>
                                </ScrollAnimation>
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <div className="read-more-btn mt--40">
                                        <Link className="btn-default btn-icon" to="contact">Get More Info <i className="icon"><FiArrowRight /></i></Link>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default AboutTwo;
