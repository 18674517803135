import React from 'react';
import { FiActivity, FiCast, FiMap } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import "../../styles/myStyle.css";
const ServiceList = [
    {
        icon: '/images/service/stunning_website_design.svg',
        title: 'Stunning website design',
        description: 'Our team of experts designs visually appealing websites that convert visitors into customers.<span style="color:#2d96a4;"> Our design services include UI/UX design, graphic design, and more.</span>'
    },
    {
        icon:'/images/service/custom_software_developmet.svg',
        title: 'Custom software development',
        description: 'Our team of developers creates software tailored to your business needs.<span style="color:#2d96a4;"> We specialize in desktop software, mobile apps, and web-based solutions.</span>'
    },
    {
        icon: '/images/service/digital_marketing_services.svg',
        title: 'Digital marketing services',
        description: 'Unobi Tech offers a wide range of digital marketing services to help your business<span style="color:#2d96a4;"> increase online visibility and reach your target audience.</span> Services include SEO, social media marketing, and more.'
    },
    {
        icon: '/images/service/iot_and_rpa_solutions.svg',
        title: 'IoT and RPA solutions',
        description: 'Unobi Tech helps businesses stay ahead of the <span style="color:#2d96a4;">competition by integrating IoT and RPA technology into their operations.</span> Improve efficiency and drive growth with our cutting-edge solutions.'
    },
    {
        icon: '/images/service/professional_graphics_design.svg',
        title: 'Professional graphics design',
        description: "Unobi Tech's graphic design services include logo design, branding, and other visual elements that<span style='color:#2d96a4;'> help elevate your business and increase brand awareness.</span>"
    },
    {
        icon: '/images/service/video_editing_services.svg',
        title: 'Video editing services',
        description: "Unobi Tech's video editing services bring your product or service to life. Our team of experts can<span style='color:#2d96a4;'> help you create engaging, high-quality videos</span> for any purpose."
    },

    {
        icon: '/images/service/SEO_Services.svg',
        title: 'SEO services',
        description: "Unobi Tech's SEO services help your website rank higher on search engine results,<span style='color:#2d96a4;'> bringing more traffic and increasing online visibility.</span>"
    },
    {
        icon: '/images/service/talent_sourcing.svg',
        title: 'Talent sourcing',
        description: "Unobi Tech's talent sourcing services help businesses find top talent in various fields. We specialize in<span style='color:#2d96a4;'> finding the best candidates for your business needs.</span>"
    },
    {
        icon: '/images/service/business_development.svg',
        title: 'Business development',
        description: "Unobi Tech's business development services help you take your business to the next level. Our team of experts can help you with<span style='color:#2d96a4;'> market research, strategic planning, and more.</span>"
    },
]

const ServiceOne = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                <img src={`${val.icon}`} className="service-img-height" alt='Unobi Technologies'/>
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceOne;